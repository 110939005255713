import { useContext, useEffect, useRef, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { postFormMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import ImageUpload from "../../general/ImageUpload";

function AwardsAdd({ onBackClick }) {

  const { setLoading } = useContext(StateContext);

  const [awardName, setAwardName] = useState("");
  const [achievements, setAchievements] = useState("");

  const [image, setImage] = useState(null);
  const imageInputRef = useRef(null);

  const handleClick = () => {
    imageInputRef.current.click(); // Trigger the file input dialog
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImage(selectedFile);
    // Do something with the selected file
  };

  const submitHandler = (e) => {
    e.preventDefault()
    if (!image) {
      console.log("Image is required")
      toastError("Image is required")
    }
    else{

      setLoading(true);

  



      // checking of the values changed in field
  
      var formdata = new FormData();
      if (image !== null) {
        formdata.append("awardImage", image);
      }
      if (awardName) {
        formdata.append("awardName", awardName);
      }
      if (achievements) {
        formdata.append("achievement", achievements);
      }
  
      postFormMethod(`awards/addAward`, formdata)
        .then((res) => {
          setLoading(false);
          if (res.status == true) {
            setLoading(false);
            console.log("RESPONSE AWARDS : ", res);
  
            toastSuccess(res.message);
            onBackClick();
          }
          else{
            toastError(res.message)
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });

    }
   
  };

  useEffect(() => {

    window.scrollTo(0,0)
     
  }, [])


  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Add New Award</div>
              <div className="text-grey-2">Add Award Details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0 w-1/6 items-center">
            <button
              className="btn-sea-green-thick lg:w-full"
             type="submit"
            >
              Add
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* COMPANY DETAILS */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* Logo input */}
          {/* <div className="flex flex-col gap-4">
            <div className="text-black-1">
              Logo<span className="text-orange-1">*</span>
            </div>
            <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28">
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="upload"
                  className="flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                />
              ) : (
                <img
                  src="assets/documentupload.svg"
                  alt="upload"
                  className="m-auto cursor-pointer"
                  onClick={handleClick}
                />
              )}

              <input
                type="file"
                ref={imageInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
                accept="image/*" // Accept only image files
                // required={true}
              />
            </div>
          </div> */}

<ImageUpload
            thumbnail={image}
            setThumbnail={setImage}
            label={"Logo"}
            required={true}
          />

          {/* Input fields */}

   
          <div className="flex flex-col gap-4">
            <div className="text-black-1">
             Source<span className="text-orange-1">*</span>
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Add Source"
                value={awardName}
                onChange={e => setAwardName(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-black-1">
             Award Name<span className="text-orange-1">*</span>
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Add Award Name"
                value={achievements}
                onChange={e => setAchievements(e.target.value)}
                required={true}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">Add</button>
        </div>
      </div>
    </form>
  );
}

export default AwardsAdd;
