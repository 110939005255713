import React, { useState } from 'react'
import DeleteConfirmation from '../modals/DeleteConfirmation';
import Tooltip from '../Tooltip';

function Reference({name, designation, image, linkedin, handleDelete, id, onEdit, companyName, companyPic }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteClick = () => {
        // console.log("ID in reference compo: ", id)
        // handleDelete(id)
        setShowDeleteModal(true)
      };
    const handleEditClick = () => {
        console.log("ID in reference compo: ", id)
        onEdit(true,id)
      };
  return (
    <div
      key={id}
      className="flex flex-col bg-white rounded-md p-5"
      style={{ boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.07)" }}
    >
      {/* DETAILS */}
      <div className="flex-col gap-2 justify-center items-center">
        {/* Image */}
        <div className="flex justify-between">
          <img
            src={image ? image : "/assets/image-not-found.png"}
            alt="title"
            className="h-[61px] w-[62px] rounded-full"
          />

                {/* linkedIn */}

    

          {
            
           <a href={linkedin} target="_blank"> 
          <div className={`mx-auto flex justify-center items-center ${linkedin ? "cursor-pointer" : "cursor-not-allowed"}`}>
            <div className="w-10 h-10 rounded-full bg-light-sea-green flex items-center jusify-center">
              <img
                src="assets/linkedin.svg"
                alt="title"
                className="w-14 h-14 object-contain"
              />
            </div>
          </div>
      </a>
          }

     
        </div>

        {/* Name and description */}
        <div className="flex flex-col w-7/12 m-2">
          <div className="text-lg font-semibold">{name}</div>
          <div className="text-grey-9 font-light text-sm">{designation}</div>
          <div className="flex gap-1 flex-shrink-0 text-grey-9 font-light text-xs items-center">
            {/* <div className="">
              <img className="rounded-md h-6 w-6" src={companyPic} />
            </div> */}
            <div className="font-bold max-w-prose line-clamp-1">{companyName}</div>
          </div>
        </div>
         


      </div>

      {/* ACTION BUTTONS */}
      <div className="flex items-center justify-between">
        <div>

        </div>
        <div className="flex gap-2">

        <div className="">
          <button
            className=""
            onClick={handleEditClick}
          >
           <img src="assets/edit.svg" alt="edit" />
          </button>
        </div>
        <div
          role="button"
          className=""
          onClick={handleDeleteClick}
        >
          <button>

          <img src="assets/trash.svg" alt="delete" />
          </button>
        </div>
      </div>

        </div>

        {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => handleDelete(id)}
        />
      )}
        
    </div>
  );
}

export default Reference




