import { useContext, useEffect, useState, useCallback } from "react";
import { deleteMethod, postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import DataNotFound from "../../general/DataNotFound";
import CardRanking from "../../general/ranking/CardRanking";
import Loader from "../../general/Loader";

function ProductsMain({ onEditClick, fetchIsProductEdit, activePage }) {
  const {setLoading} = useContext(StateContext);
  
  const [myProducts, setMyProducts] = useState(null);

  // pagination variables & function
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(activePage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  function changeLimitHandler(limit) {

    setLimit(limit); 
    setPage(1);

  }

  const [searchParams, setSearchParams] = useState("")

    // searchbar functions
    const [keyword, setKeyword] = useState("");
    function fetchKeyword(keyword) {
      console.log("KEYWORD : ", keyword);
  
      setKeyword(keyword);
    }

  // function fetchSearchValues(keyword, productTypeId){
  //   console.log("PROD ID: ", productTypeId)
  //   const updatedParams = {keyword: keyword, productTypeId : [productTypeId]}
  //   setSearchParams({...updatedParams})
  // }

  const deleteProductHandler = (productId) => {
    // setDelete(false);
    let pageNumber = page;
    deleteMethod(`product/deleteProduct/${productId}`)
      .then((res) => {
        if (res.status === true) {
       

          if(myProducts?.length < 2){
            pageNumber = page - 1
            
          }
          RefreshProductsApi(1)

          toastSuccess(res.message);
          // setMyProducts(
          //   myProducts.filter((product) => product.id !== productId)
          // );
        }
        else  {
        toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  function editProductHandler(bool, id) {
    const obj = {
      isEdit: bool,
      productId: id,
    };
    // setEditProduct({ ...obj });
    fetchIsProductEdit(obj,page);
  }

  const RefreshProductsApi = (pageNumber) => {
    setLoading(true);

    let raw;
  
      // if(searchParams.productTypeId === 0){
      //    raw = JSON.stringify({
      //     productTypeIds: [],
      //     keyword: searchParams.keyword,
      //   });
      // }else{
  
      //   raw = JSON.stringify({
      //     // productTypeIds: searchParams.productTypeId,
      //     keyword: searchParams.keyword,
      //   });
  
      // }

        raw = JSON.stringify({
          // productTypeIds: searchParams.productTypeId,
          keyword: keyword,
        });
  
      postMethod(`product/getMyProducts?limit=${limit}&page=${pageNumber}`, raw)
        .then((res) => {
          setLoading(false)
          if (res?.status) {
            setMyProducts(res.data?.results);
            setLimit(res.data?.limit);
            setPage(res.data?.page);
            setTotalPages(res.data?.totalPages);
            setTotalResults(res?.data?.totalResults)
           
          }
          else{
            toastError(res?.message)
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
        });
  }

  const getProductsApiCall = useCallback(async (pageNumber, whichTime) => {
    setIsLoading(true);

    const raw = JSON.stringify({ keyword });

    try {
      const res = await postMethod(`product/getMyProducts?limit=${limit}&page=${pageNumber}`, raw);
      if (res?.status) { 

          if(whichTime === "firstTime" && myProducts === null){
  
            setMyProducts(res.data.results);
          }else if(whichTime === "searchTime"){

            setMyProducts(res.data.results);

          }
          else{
            setMyProducts(prevProducts => {
              if(prevProducts){

                return [...prevProducts, ...res.data.results]
              }else{
                return res.data.results;
              }
            });
          }

        setTotalResults(res?.data?.totalResults)
        setPage(res.data.page);
        setHasMore(res.data.results.length > 0);
      } else {
        toastError(res?.message);
      }
    } catch (error) {
      toastError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [keyword, limit]);

  // useEffect(() => {
  //   setLoading(true);

  // let raw;

  //   // if(searchParams.productTypeId === 0){
  //   //    raw = JSON.stringify({
  //   //     productTypeIds: [],
  //   //     keyword: searchParams.keyword,
  //   //   });
  //   // }else{

  //   //   raw = JSON.stringify({
  //   //     productTypeIds: searchParams.productTypeId,
  //   //     keyword: searchParams.keyword,
  //   //   });

  //   // }
  //   raw = JSON.stringify({
  //     // productTypeIds: searchParams.productTypeId,
  //     keyword: keyword,
  //   });

  //   postMethod(`product/getMyProducts?limit=${limit}&page=${page}`, raw)
  //     .then((res) => {
  //       setLoading(false)
  //       if (res?.status) {
  //         setMyProducts(res.data?.results);
  //         setLimit(res.data?.limit);
  //         setPage(res.data?.page);
  //         setTotalPages(res.data?.totalPages);
         
  //       }
  //       else{
  //         toastError(res?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toastError(error.message);
  //       console.log("error.message: ", error.message);
  //     });
  // }, [ keyword, limit, page]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 && hasMore && !isLoading) {
        if(totalResults==null || myProducts?.length < totalResults){
          getProductsApiCall(page + 1,"scrollTime");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, hasMore, isLoading]);

  useEffect(() => {
   
      let api;
       if(keyword.length % 3 === 0 ){
        api = getProductsApiCall(1, "searchTime");
      } else if(keyword.length === 0){

        api = getProductsApiCall(1, "firstTime");

      }
  return () => api;
  }, [keyword]);

  useEffect(() => {
   
        window.scrollTo(0,0);
    
  }, [])

 
if( myProducts === null ){
  return <Loader/>
}
  return (
    
    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">Products</div>
          <div className="text-grey-2 text-[16px] capitalize">
            Add new product profiles.
          </div>
        </div>
        <div className="flex mt-5 lg:mt-0 lg:justify-end">
          <button className="btn-sea-green-thick" onClick={onEditClick}>
            Add Product
          </button>
        </div>
      </div>

      {/* Body */}

      {/* SEARCH BAR */}

      {/* <DropdownSearchbar url={'product/getProductTypes'} fetchSearchValues={fetchSearchValues} placeholder={"Search Products"}/> */}
      {/* <Searchbar fetchKeyword={fetchKeyword} /> */}
      <div className="flex items-center rounded-lg border border-grey-7 h-12">
    <div className=" border-y-0 border-l-0 h-full flex justify-center items-center pl-4">
      <img src="assets/search.svg" alt="search" className="" />
    </div>
    <input
      className="focus:border-transparent outline-none mx-5 w-full h-full"
      placeholder="Search"
      value={keyword}
      onChange={ e => { 

        setKeyword(e.target.value)
        if (e.target.value.length % 3 === 0) {  
          setPage(1)
          setKeyword(e.target.value);
        } else if (e.target.value.length < 1) {
          setKeyword("");
        }
        
      } }
    />
  </div>

      {/* CONTENT */}

      {
        myProducts?.length > 0 ?

        <>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2">
          {myProducts?.map((product, index) => {
            return (
              <Products
                key={index}
                id={product.id}
                category={product.productType}
                title={product?.name}
                description={product.description}
                onDelete={deleteProductHandler}
                onEdit={editProductHandler}
              />
            );
          })}

        </div> */}
        <CardRanking initialCards={myProducts?myProducts:[]} onDelete={deleteProductHandler} onEdit={editProductHandler}/>
        {isLoading && <div className="flex justify-center">Loading more products...</div>}
        {/* PAGINATION */}
  
       {/* <Pagination lim={limit} currPage={page} pages={totalPages} setLim={changeLimitHandler} setCurrPage={setPage} label={"Products"} counts ={[5, 10, 15]}/> */}
        </>
       :
       <DataNotFound/>
      }


      {/* <ToastContainer/> */}
    </div>
  );
}

export default ProductsMain;
