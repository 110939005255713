import {React, useContext} from 'react'
import { ContentVideoContext } from '../../../../Context/ContentVideoContext';

function AddVideoCard({tag, openAddVideo}) {
    
    const { videoRemark, setVideoRemark } = useContext(ContentVideoContext);

    const addVideoHandler = () => {

        if(tag === 'featured'){
            setVideoRemark({value:'featuredVideo', type:"btn2", status:true})
        }
        else if(tag === 'demo'){
            setVideoRemark({value:'demoVideo', type:"btn2", status:true})
        }
        else if(tag === 'firstCall'){
            setVideoRemark({value:'videoCall', type:"btn2", status:true})
        }

        openAddVideo();

    }
  return (
    
    <div className="flex flex-col bg-white rounded-lg border border-grey-7">
    {/* image */}
    <div className="h-[160px] relative rounded-t-lg overflow-clip bg-center bg-grey-11" style={{backgroundImage:"url(assets/image-outline.svg)", backgroundRepeat:"no-repeat"}}>
        <div className={`corner-ribbon ${tag === "featured"?'yellow': tag == 'firstCall' ? "green" :  "mustard"} flex gap-1 justify-center items-center`}>
            <div className='rounded-full bg-white w-5 h-5 flex justify-center items-center'>
                <img src="assets/podcast-icon.svg" alt="tag"/>
            </div>
            <div className='text-base font-medium text-white capitalize'>
                {tag}
            </div>
        </div>
        
            
        
    </div>

    <div className='flex px-3 justify-between w-full'>  
        <button className='btn-sea-green flex gap-2 w-full justify-center m-2' 
        onClick={addVideoHandler}><div><img src="assets/upload-icon.svg" alt="upload"/></div>Add <span className="capitalize">{tag ==='firstCall'? "First Call" : tag}</span> Video</button>
    </div>

</div>
  )
}

export default AddVideoCard
