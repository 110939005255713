import React, { useContext, useState, useEffect } from "react";
import ContentCard from "../../general/cards/content/ContentCard";
import VideoCard from "../../general/cards/content/VideoCard";
import VideoUpload from "../../general/modals/VideoUpload";
import { ContentVideoContext } from "../../../Context/ContentVideoContext";
import { StateContext } from "../../../Context/StateContext";
import { deleteMethod, getMethod, postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { ToastContainer } from "react-toastify";
import TopContent from "./TopContent";
import ContentRanking from "../../general/ranking/ContentRanking";

function Content({ onEditClick, fetchIsContentEdit }) {

  const { setLoading } = useContext(StateContext);

  // pagination variables & function
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);

  function changeLimitHandler(limit) {

    setLimit(limit); 
    setPage(1);

  }

  const [openModal, setOpenModal] = useState(false);
  const { videoSrc, setVideoSrc ,videoRemark, setVideoRemark,setCallTopContentApi } = useContext(ContentVideoContext);
  const [contentData, setContentData] = useState([]);

  const deleteContentHandler = (contentId) => {
    // setDelete(false);
    deleteMethod(`content/deleteContent/${contentId}`)
      .then((res) => {
        console.log(res);
        if (res.status == true) {
           // Create a new object with filtered content
        const updatedContentData = {};

        // Loop through each category and filter items
        for (const [category, content] of Object.entries(contentData)) {
          updatedContentData[category] = {
            ...content,
            items: content.items.filter(item => item.id !== contentId),
          };
        }

        setContentData(updatedContentData);
          // setContentData(
          //   contentData.filter((content) => content.id != contentId)
          // );
          setCallTopContentApi(prev => !prev)
          toastSuccess("Content deleted!");
        } else {
          toastError(res.message);
        }
        getMyContentApi()
      })

      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  function editContentHandler(bool, id) {
    console.log("EDIT ID CONTENT: ", id);
    const obj = {
      isEdit: bool,
      contentId: id,
    };
    setVideoRemark({type:'btn3', status:true, value: ""})
    fetchIsContentEdit(obj);
  }

  function closeModal() {
    setOpenModal(false);
    onEditClick();
  }

  const handleVideoUpload = (videoFile) => {
    console.log("VIDEO FILE in content: ", videoFile);
    setVideoSrc(videoFile);
    if (videoSrc) {
      onEditClick();
    }
  };

  const [searchParams, setSearchParams] = useState({
    keyword: "",
    productTypeId: [],
  });

  function fetchSearchValues(keyword, productTypeId) {
    console.log("PROD ID content: ", productTypeId);
    const updatedParams = { keyword: keyword, productTypeId: [productTypeId] };
    setSearchParams({ ...updatedParams });
  }

  const getMyContentApi = () => {

    setLoading(true);

    console.log("SEARCH PARAMs : ", searchParams);

    let raw = {}

    if(searchParams.keyword.length > 0){
      raw ={...raw, keyword : searchParams.keyword}
    }
    if(searchParams.productTypeId.length > 0 && searchParams.productTypeId[0]){
      raw ={...raw, typeIds : searchParams.productTypeId}
    }

  

    postMethod(`content/getMyContent?limit=${limit}&page=${page}`, JSON.stringify(raw))
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          console.log("Response content : ", res.data.results);

          setContentData(res.data);

          // pagination variables
          setLimit(res.data?.limit);
          setPage(res.data?.page);
          setTotalPages(res.data?.totalPages);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });

  }
  useEffect(() => {

    getMyContentApi();
   
  }, [searchParams, limit, page]);

  useEffect(() => {

    window.scrollTo(0,0)
     
  }, [])

  return (

      <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
        {/* header */}
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex flex-col lg:w-9/12">
            <div className="font-medium text-[28px]">Content</div>
            <div className="text-grey-2 text-xs leading-[18px]">
              Videos for Featured, First Call and Demo are required for your
              profile. The Featured video is your pitch in 2 mins or less. The
              First Call video is the most recent message of your company
              explaining all the basic information about your company and
              product(s). The Demo is intented for a short overview of the user
              experience. You can add other video content below and categorize
              appropriately.
            </div>
          </div>
          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button
              className="btn-sea-green-thick"
              // onClick={() => {setOpenModal(true); setVideoRemark({type: "btn1", status: true, value:""})}}
              onClick={onEditClick}
            >
              Add Video
            </button>
          </div>
        </div>

        {/* Body */}

        {/* CONTENT */}

        <TopContent edit={editContentHandler} onDelete={deleteContentHandler} openAddVideo={onEditClick} />
       
        {/* DEMOCARDS */}


        <div className="grid grid-cols-1 gap-2">
          {
            Object.entries(contentData)?.length > 0 ?
            <div className="text-3xl font-bold mb-2">
          Your Video Content :
        </div> :""
          }
        
          {Object.entries(contentData)?.map(([category, content]) => 
             (
              <div key={category} className="mb-6">
          <div className="text-xl font-bold mb-2">{category}</div>
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {content.items.map(item => (
              <ContentCard
                title={item?.title}
                video={item?.poster}
                category={item?.contentType}
                onDelete={deleteContentHandler}
                onEdit={editContentHandler}
                markedVideo={item?.markedVideo}
                id={item?.id}
                setRefresher = {getMyContentApi}
                videoUrl = {item?.videoLink}
              />
            ))}
          </div> */}
          <ContentRanking initialCards={content?.items} onDelete={deleteContentHandler} onEdit={editContentHandler} setRefresher={getMyContentApi} contentTypeId={content?.id}/>       
        </div>
            )
          )}
        </div>

        {/* PAGINATION */}

        {/* <Pagination lim={limit} currPage={page} pages={totalPages} setLim={changeLimitHandler} setCurrPage={setPage}/> */}

        {openModal && (
          <VideoUpload onClose={closeModal} onUpload={handleVideoUpload} />
        )}

        {/* <ToastContainer /> */}
      </div>
   
  );
}

export default Content;
