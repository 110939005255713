import { HashLoader} from 'react-spinners';

export default function NewsLoader(){
    return (
        <div style={{width:"100vw",height:"100vh",position:"fixed",top:"0",left:"0",
            backgroundColor:"rgba(210, 210, 210, 0.95)",backdropFilter:"blur(15px)",
            zIndex:"9999"}} className={`flex-col flex justify-center items-center text-center text-sea-green`}
        >

     <div className="mb-10 flex-col">
    <div className="text-5xl font-extrabold">Just a moment! </div> <br/><div className="font-light text-3xl mt-1">We are retrieving the details of your news article.</div>
         </div>

          <HashLoader size={`100`} color="#1CBAAB"></HashLoader>

        </div>
    );
}
