import { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../general/NewInputField";
import {
  deleteMethod,
  getMethod,
  postFormMethod,
  postMethod,
} from "../../../utils/apiMethod";
import SelectProductType from "../../general/SelectProductType";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from "../../../utils/Toasters";
import "react-toastify/dist/ReactToastify.css";
import { StateContext } from "../../../Context/StateContext";
import AddTestimonial from "../../general/modals/AddTestimonial";
import EditTestimonial from "../../general/modals/EditTestimonial";
import { MdEdit } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import ResourceUpload from "../../general/ResourceUpload";

function ProductsEdit({ onBackClick, productId }) {
  const { setLoading } = useContext(StateContext);

  const [openAddTestiModal, setOpenAddTestiModal] = useState(false);
  const [openUpdateTestiModal, setOpenUpdateTestiModal] = useState(false);
  const [testimonialId, setTestimonialId] = useState("");

  const [refresher, setRefresher] = useState(false);

  const [benefits, setBenefits] = useState([""]);
  const [testimonials, setTestimonials] = useState([
    {
      customerName: "",
      title: "",
      description: "",
    },
  ]);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productType, setProductType] = useState("");

  //   const prodTypeNames = productTypeDetails?.map((data, index)=>{
  //     return {name: data?.productTypeDefined?.name}
  //   })

  const [productDetails, setProductDetails] = useState({
    name: "",
    description: "",
    benefits: [],
    testimonials: [],
    productType: "",
    productVideo: "",
  });

  const [productImage, setProductImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setProductImage(selectedFile);
  };

  // video functions

  const [productVideo, setProductVideo] = useState(null);
  const videoFileInputRef = useRef(null);

  const handleVideoClick = () => {
    if (videoFileInputRef.current) {
      videoFileInputRef.current.click();
    }
  };

  const handleVideoFileChange = (e) => {
    const selectedVideoFile = e.target.files[0];
    setProductVideo(selectedVideoFile);
  };

  // resources functions

  const [resources, setResources] = useState([]);

  const [resourcesList, setResourcesList] = useState([]);
  const resourceFileInputRef = useRef(null);

  const handleResourceFileChange = (event) => {
    let files = [...event.target.files];
    console.log("FILES: ", files);

    const newResources = Array.from(files).map((file) => ({
      fileType: file.type.startsWith("image/")
        ? "image"
        : file.type.startsWith("video/")
        ? "video"
        : "file",
      url: URL.createObjectURL(file),
    }));

    setResourcesList((prevResources) => [...prevResources, ...newResources]);
    setResources((prevResources) => [...prevResources, ...files]);
  };

  const handleAddMoreResourceClick = (e) => {
    // Trigger file input click
    console.log("Event: ", e);
    if (resourceFileInputRef.current) {
      resourceFileInputRef.current.click();
    }
  };

  const handleDeleteResource = (resourceId, idx) => {
    if (resourceId) {
      getMethod(`product/removeProductMedia/${resourceId}`)
        .then((res) => {
          if (res.status === true) {
            //   setOpen(false);
            setResourcesList(
              resourcesList.filter((resource) => resource.id !== resourceId)
            );
            toastSuccess("Resource deleted!");
          }
        })
        .catch((error) => {
          toastError(error.message);
          console.log("Error: ", error.message);
        });
    } else {
      setResourcesList((prevFile) => {
        const newArray = [...prevFile];
        newArray.splice(idx, 1);
        return newArray;
      });

      // let newArray = [...resourcesList];

      // newArray.splice(idx,1)
      // setResourcesList(newArray)

      // console.log("NEW ARRAY: ", newArray)

      // console.log("Inside delete fn :",resourcesList)
      toastInfo("Resource eliminated!");
    }
  };

  // other functions

  // BENEFITS functions
  const handleBenefitChange = (index, value) => {
    const newBenefits = [...benefits];
    newBenefits[index] = value;
    setBenefits(newBenefits);
  };

  const handleAddBenefits = () => {
    setBenefits([...benefits, ""]);
  };

  const cancelBenefitHandler = (index) => {
    let arr = [...benefits];
    arr.splice(index, 1);
    setBenefits(arr);
  };

  // TESTIMONIALS functions
  const handleAddTestimonials = () => {
    // setTestimonials([
    //   ...testimonials,
    //   {
    //     customerName: "",
    //     title: "",
    //     description: "",
    //   },
    // ]);

    setOpenAddTestiModal(true);
  };
  const handleUpdateTestimonial = (id) => {
    // setTestimonials([
    //   ...testimonials,
    //   {
    //     customerName: "",
    //     title: "",
    //     description: "",
    //   },
    // ]);

    setTestimonialId(id);
    setOpenUpdateTestiModal(true);
  };

  const deleteTestimonial = (id, index) => {
    // let arr = [...testimonialDetails];
    // arr.splice(index,1);
    // setTestimonialDetails(arr);

    deleteMethod(`product/deleteTestimonial/${id}`)
      .then((res) => {
        console.log("RESPONSE: ", res);
        if (res.status === true) {
          //   setOpen(false);
          setTestimonials(testimonials?.filter((item) => item.id !== id));

          toastWarn(res.message);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };

  // const fetchSelectedOption = (prodType) => {
  //   // const filteredProductTypeDetails = productTypeDetails.filter(item =>
  //   //     prodType.includes(item.productTypeDefined.name)
  //   //   );

  //   //   console.log("filteredProductTypeDetails: ", filteredProductTypeDetails)

  //   setProductType(prodType);
  // };
  function validateUrl(url) {
    try {
      let validate = new URL(url);

      return true;
    } catch (error) {
      throw new Error(`Video URL not valid!`);
    }
  }
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    // checking of the values changed in field

    try{
      var raw = {
        productId: productId,
      };
  
  
      if (productDetails.name !== productName) {
        raw = { ...raw, name: productName };
      }
      if (productDetails.description !== productDescription) {
        raw = { ...raw, description: productDescription };
      }
      if (productDetails.productType !== productType) {
        raw = { ...raw, productType: productType };
      }
      if (productDetails.productVideo !== productVideo) {
        if(productVideo.length > 0){
          validateUrl(productVideo);
          raw = { ...raw, productVideo: productVideo };
        }
        raw = { ...raw, productVideo: productVideo };
      }
  
      raw = JSON.stringify(raw);
  
      postMethod(`product/updateProduct`, raw)
        .then((res) => {
          if (res.status === true) {
            var raw = JSON.stringify({
              productId: productId,
              benefits: benefits,
            });
  
            postMethod(
              "product/updateBenefitsTestimonialsProductTypes",
              raw
            ).then((res) => {
              if (res.status === true) {
                var mediaFormData = new FormData();
                mediaFormData.append("productId", res?.data?.id);
  
                resources.forEach((resource) => {
                  mediaFormData.append("productResources", resource);
                });
  
                postFormMethod("product/addUpdateProductMedia", mediaFormData)
                  .then((response) => {
                    setLoading(false);
  
                    if (response.status === true) {
                      toastSuccess(res.message);
                      onBackClick();
                    } else {
                      toastError(res.message);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    toastError(error.message);
                    console.log("error.message: ", error.message);
                  });
              }
            });
          }
          else{
            setLoading(false)
            toastError(res.message)
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });

    }catch(error){
      setLoading(false);
      toastError(error.message)
    }

   
  };

  const videoEditHandler = () => {
    setProductVideo(null);
  };

  useEffect(() => {
    setLoading(true);
    getMethod(`product/viewProduct/${productId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          // console.log("Response from videwProd: ", res);

          // set benefits
          let respBenefits = [];
          let respTestimonials = [];
          res.data?.benefits.forEach((benefit) => {
            respBenefits.unshift(benefit.description);
          });
          setBenefits(respBenefits);

          // set testimonials
          res.data?.testimonials.forEach((testimonial) => {
            respTestimonials.push(testimonial);
          });
          setTestimonials(respTestimonials);

          // set product name, description and image
          setProductName(res.data?.name);
          // setProductImage(res.data?.productImageUrl);
          setProductDescription(res.data?.description);
          setProductVideo(res?.data?.productVideo);
          setProductType(res?.data?.productType);
          

          // let videoMedia = res.data?.media?.filter((media) => {
          //   return media.type === "video";
          // });

          // setProductVideo(videoMedia[0]?.mediaUrl);

          let resourceMedia = res.data?.media
            ?.filter((media) => {
              return media.type === "resource";
            })
            .map((res, idx) => {
              return {
                type: "resource",
                url: res.mediaUrl ? res.mediaUrl : "",
                id: res.id,
                fileType: res.fileType,
              };
            });

          setResourcesList(resourceMedia);

          // set product-type names for sending as props to selectProductType
          
         

          // set complete productType details
          // setProductTypeDetails(res.data?.prodTypes);

          // set product details
          setProductDetails(res.data);
          // setProductDetails({
          //   ...productDetails,
          //   // video: videoMedia[0]?.mediaUrl,
          //   // productImageUrl: res?.data?.productImageUrl,
          // });
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [refresher]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("PRODUCT VIDEO : ", productVideo);

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit product info</div>
              <div className="text-grey-2">Edit or modify product details</div>
            </div>
          </div>

          <div className="flex mt-5 lg:mt-0 lg:justify-end">
            <button className="btn-sea-green-thick" type="submit">
              Save changes
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* Product info */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* Product details */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <InputField
              label="Product name"
              placeholder="Product full name"
              width={"w-full"}
              value={productName}
              optional={false}
              onChange={(e) => setProductName(e.target.value)}
            />

            <InputField
              label="Product Description"
              placeholder="Product Description"
              width={"w-full"}
              value={productDescription}
              optional={false}
              onChange={(e) => setProductDescription(e.target.value)}
            />

            {/* <SelectProductType productType={productType} fetchSelectedOption={fetchSelectedOption}/> */}

            {/* <SelectProductType
              prodTypeDetails={productType}
              fetchSelectedOption={fetchSelectedOption}
              width={"w-full"}
            /> */}

            <InputField
              label="Product Type"
              placeholder="Product Type"
              width={"w-full"}
              maxLength={30}
              value={productType}
              optional={false}
              onChange={(e) => setProductType(e.target.value)}
            />
            <InputField
              label="Product Video"
              placeholder="Product Video Link"
              width={"w-full"}
              optional={true}
              value={productVideo}
              onChange={(e) => setProductVideo(e.target.value)}
            />
          </div>
        </div>

        {/* Benefits */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          <div>Benefits:</div>
          <div className="flex flex-col gap-3">
            {benefits.map((value, index) => {
              return (
                // <div key={index}>
                //   <InputField
                //     label={`Benefit ${index + 1}`}
                //     placeholder="Enter Benefit"
                //     width="full"
                //     value={value}
                //     onChange={(e) => handleBenefitChange(index, e.target.value)}
                //     maxLength={72}
                //   />
                <>
                  <div className="font-light">
                    {`Benefit ${index + 1}`}{" "}
                    <span className="text-orange-1">*</span>
                    <span className="text-grey-12">
                      &nbsp;( max 100 characters )
                    </span>
                  </div>
                  <div
                    className={`flex items-center rounded-lg border border-grey-7 h-12 justify-between px-5`}
                  >
                    <input
                      className="focus:border-transparent outline-none w-full h-full"
                      placeholder="Enter Benefit"
                      value={value}
                      required={benefits?.length < 2}
                      onChange={(e) =>
                        handleBenefitChange(index, e.target.value)
                      }
                      maxLength={100}
                    />
                    {benefits?.length > 1 && (
                      <div
                        role="button"
                        onClick={() => cancelBenefitHandler(index)}
                      >
                        <img src="/assets/cancel.svg" alt="cancel" />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div
              role="button"
              onClick={handleAddBenefits}
              className={`text-sea-green font-medium cursor-pointer ${benefits?.length < 1 && "border border-sea-green md:px-2 py-1 lg:px-4 lg:py-2 w-1/3 md:w-1/12 text-center rounded-md"}`}
            >
              <span>{benefits?.length > 0 ? "Add More" : "Add"}</span>
            </div>
          </div>
        </div>

        {/* Testimonials */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          <div>Testimonials:</div>

          {testimonials.map((testimonial, index) => {
            return (
              <div key={index} className={`flex flex-col gap-4 w-full`}>
                <div className="font-light">
                  Testimonial {index + 1}
                  <span className="text-orange-1">*</span>
                </div>
                <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-2">
                  <div className="flex gap-2 flex-row-reverse items-center">
                    <div
                      className="flex cursor-pointer"
                      onClick={() => deleteTestimonial(testimonial?.id, index)}
                    >
                      <img src="/assets/cancel.svg" alt="cancel" />
                    </div>
                    <div
                      className="flex cursor-pointer"
                      onClick={() => handleUpdateTestimonial(testimonial?.id)}
                    >
                      {/* <img src="/assets/cancel.svg" /> */}
                      <MdEdit className="text-sea-green text-xl" />
                    </div>
                  </div>
                  {/* <input
                    className="focus:border-transparent outline-none w-full h-full"
                    placeholder="Enter Testimonial"
                    value={testimonial.description}
                    name="description"
                    onChange={(e) => handleTestimonialChange(index, e)}
                  /> */}
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      placeholder="Client Name"
                      className="focus:border-transparent outline-none bg-[#f2f8f8] m-auto"
                      value={testimonial?.customerName}
                      name="customerName"
                      readOnly={true}
                      // onChange={(e) => handleTestimonialChange(index, e)}
                    />
                  </div>
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      className="focus:border-transparent outline-none bg-[#f2f8f8] m-auto"
                      value={testimonial?.companyName}
                      name="Client Company Name"
                      readOnly={true}
                      // onChange={(e) => handleTestimonialChange(index, e)}
                    />
                  </div>
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      placeholder="Client Position or Title"
                      className="focus:border-transparent 
                                  outline-none bg-[#f2f8f8] m-auto"
                      value={testimonial?.title}
                      name="title"
                      readOnly={true}
                      // onChange={(e) => handleTestimonialChange(index, e)}
                    />
                  </div>

                  <input
                    className="focus:border-transparent outline-none w-full h-full"
                    placeholder="Client Quote"
                    value={testimonial?.description}
                    name="description"
                    readOnly={true}
                    // onChange={(e) => handleTestimonialChange(e)}
                  />

                  {/* Testimonial Image */}

                  <div className="flex gap-2 items-center">
                    <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-14 w-14 cursor-pointer">
                      <div className="flex items-center m-auto h-full w-full rounded-2xl">
                        <img
                          src={
                            testimonial?.imageUrl &&
                            testimonial?.imageUrl.length > 0
                              ? testimonial?.imageUrl
                              : "/assets/documentupload.svg"
                          }
                          alt="upload"
                          className={`${
                            testimonial?.imageUrl &&
                            testimonial?.imageUrl.length > 0
                              ? "flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                              : "m-auto cursor-pointer"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div
            role="button"
            onClick={handleAddTestimonials}
            className={`text-sea-green font-medium cursor-pointer ${testimonials?.length < 1 && "border border-sea-green md:px-2 py-1 lg:px-4 lg:py-2 w-1/3 md:w-1/12 text-center rounded-md"}`}
          >
            {testimonials?.length > 0 ? "Add More" : "Add"}
          </div>
        </div>

        {/* Resources */}

        {/* <div className="form-outline-wrapper-div p-8 gap-4">
          <div>Resources:</div>
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-5">
            {resourcesList.length < 1 ? (
              <div className="flex flex-col gap-4">
                <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28">
                  <div className="m-auto">
                    <img
                      src="assets/documentupload.svg"
                      alt="upload"
                      className="m-auto"
                    />
                  </div>
                </div>
              </div>
            ) : (
              resourcesList.map((resource, idx) => {
                return (
                  <div key={idx} className="">

                    <div className="flex flex-col gap-4">
                      <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28 relative">
                        <div
                          role="button"
                          className="delete-btn absolute"
                          onClick={() => handleDeleteResource(resource.id, idx)}
                        >
                          <img src="assets/trash.svg" alt="trash" />
                        </div>
                        <div className="m-auto h-full w-full rounded-2xl">
                          {resource?.fileType === "image" ? (
                            <img
                              src={
                                resource?.url ? resource.url : resource[0].url
                              }
                              alt="upload"
                              className="m-auto rounded-2xl h-full w-full"
                            />
                          ) : resource?.fileType === "video" ? (
                            <video
                              src={
                                resource?.url ? resource.url : resource[0].url
                              }
                              alt="upload"
                              className="m-auto rounded-2xl h-full w-full"
                            />
                          ) : (
                            <div className="flex flex-col gap-4">
                              <div className="flex items-center justify-center rounded-2xl bg-grey-5 h-28 w-28">
                                <div className="mx-auto">
                                  <GrDocumentText
                                    size={50}
                                    className="text-grey-10"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

            <div className="flex items-center justify-center">
              <input
                type="file"
                ref={resourceFileInputRef}
                style={{ display: "none" }}
                onChange={handleResourceFileChange}
                accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/rtf, application/vnd.apple.keynote, .key"
                multiple
              />
            </div>
          </div>
          {resourcesList?.length < 5 && (
            <div
              className="text-sea-green font-medium cursor-pointer"
              onClick={handleAddMoreResourceClick}
            >
              {resourcesList.length >= 1 ? "Add more" : "Add"}
            </div>
          )}
        </div> */}

        <ResourceUpload resourcesList={resourcesList} setResourcesList={setResourcesList} required={false} label={"Resources : "}/>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex justify-end lg:w-2/12">
          <button
            type="submit"
            className="btn-sea-green text-xl w-full"
          >
            Save changes
          </button>
        </div>
      </div>

      {/* <ToastContainer/> */}

      {openAddTestiModal && (
        <AddTestimonial
          productId={productId}
          setRefresher={setRefresher}
          onClickClose={() => setOpenAddTestiModal(false)}
        />
      )}
      {openUpdateTestiModal && (
        <EditTestimonial
          testimonialId={testimonialId}
          setRefresher={setRefresher}
          onClickClose={() => setOpenUpdateTestiModal(false)}
        />
      )}
    </form>
  );
}

export default ProductsEdit;
