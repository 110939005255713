import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Dummy = () => {
  const [startDate, setStartDate] = React.useState(null);
  const datePickerRef = useRef(null);

  const handleButtonClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  return (
    <div className="w-full border border-red" onClick={handleButtonClick}>
   
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        ref={datePickerRef}
        dateFormat="MMMM d, yyyy"
      />
    </div>
  );
};

export default Dummy;
