import React, { useCallback, useContext, useEffect, useState } from "react";
import NewsCard from "../../general/cards/Products";
import { deleteMethod, getMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import Pagination from "../../general/Pagination";
import Searchbar from "../../general/Searchbar";
import DataNotFound from "../../general/DataNotFound";
import NewsRanking from "../../general/ranking/NewsRanking";
import Loader from "../../general/Loader";

function News({ onEditClick, fetchIsNewsEdit, activePage }) {
  const { setLoading } = useContext(StateContext);

  const [newsArticles, setNewsArticles] = useState(null);

  // pagination variables & function
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(activePage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function changeLimitHandler(limit) {
    setLimit(limit);
    setPage(1);
  }

  // searchbar functions
  const [keyword, setKeyword] = useState("");
  function fetchKeyword(keyword) {
    console.log("KEYWORD : ", keyword);
    setPage(1) ;
    setKeyword(keyword);
  }

  const deleteNewsHandler = (newsId) => {
    // setDelete(false);
    let pageNumber = page;
    deleteMethod(`news/deleteNews/${newsId}`)
      .then((res) => {
        if (res.status == true) {
          
          // if(newsArticles?.length < 2){
          //   pageNumber = page - 1
          // }
          // getNewsApi(pageNumber,keyword)
          RefreshNewsApi(1)
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };

  function editNewsHandler(bool, id) {
    const obj = {
      isEdit: bool,
      newsId: id,
    };
    fetchIsNewsEdit(obj, page);
  }

  function RefreshNewsApi(pageNumber, search) {
    setLoading(true);
  
    getMethod(
      `news/getMyNews?limit=${limit}&page=${pageNumber}&keyword${search}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setNewsArticles([...res.data?.results]);

          // pagination variables
          setLimit(res.data?.limit);
          setPage(res.data?.page);
          setTotalPages(res.data?.totalPages);
          setTotalResults(res?.data?.totalResults)

        }else{
          toastError(res?.message)
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }
  function getNewsApi(pageNumber, search) {
    setLoading(true);
  
    getMethod(
      `news/getMyNews?${
        search.length > 0 ? `keyword=${search}&` : ""
      }limit=${limit}&page=${pageNumber}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setNewsArticles([...res.data?.results]);

          // pagination variables
          setLimit(res.data?.limit);
          setPage(res.data?.page);
          setTotalPages(res.data?.totalPages);

        }else{
          toastError(res?.message)
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }

  const getNewsApiCall = useCallback(async (pageNumber, whichTime) => {
    setIsLoading(true);

    try {
      const res = await getMethod(`news/getMyNews?limit=${limit}&page=${pageNumber}&keyword${keyword}`);
      if (res?.status) { 

          if(whichTime === "firstTime" && newsArticles === null){
  
            setNewsArticles(res.data.results);
          }else if(whichTime === "searchTime"){

            setNewsArticles(res.data.results);

          }
          else{
            setNewsArticles(prevArticles => {
              if(prevArticles){

                return [...prevArticles, ...res.data.results]
              }else{
                return res.data.results;
              }
            });
          }

        setTotalResults(res?.data?.totalResults)
        setPage(res.data.page);
        setHasMore(res.data.results.length > 0);
      } else {
        toastError(res?.message);
      }
    } catch (error) {
      toastError(error.message);
      console.log("error.message: ", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [keyword, limit]);

  // useEffect(() => {
  //   setLoading(true);

  //   getMethod(
  //     `news/getMyNews?${
  //       keyword.length > 0 ? `keyword${keyword}&` : ""
  //     }limit=${limit}&page=${page}`
  //   )
  //     .then((res) => {
  //       setLoading(false)
  //       if (res?.status) {
  //         setNewsArticles([...res.data?.results]);

  //         // pagination variables
  //         setLimit(res.data?.limit);
  //         setPage(res.data?.page);
  //         setTotalPages(res.data?.totalPages);

  //         console.log("RES: ", res);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toastError(error.message);
  //       console.log("error.message: ", error.message);
  //     });
  // }, [keyword, page, limit]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 && hasMore && !isLoading) {
        console.log("OUTSIDE IFFF")
        console.log("totalResults!==null || newsArticles?.length < totalResults", newsArticles?.length , totalResults)
        if(totalResults==null || newsArticles?.length < totalResults){
          console.log("INSIDE IFFF")
          getNewsApiCall(page + 1,"scrollTime");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, hasMore, isLoading]);

  useEffect(() => {
   
      let api;
       if(keyword.length % 3 === 0 ){
        api = getNewsApiCall(1, "searchTime");
      } else if(keyword.length === 0){

        api = getNewsApiCall(1, "firstTime");

      }
  return () => api;
  }, [keyword]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("NEWS ARTICLES : ", newsArticles)
  if( newsArticles === null){
    return <Loader/>
  }
  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">News</div>
          <div className="text-grey-2 text-[16px]">
            Add new articles for the users.
          </div>
        </div>
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={onEditClick}>
            Add New
          </button>
        </div>
      </div>

      {/* Body */}

      {/* SEARCH BAR */}

      <Searchbar fetchKeyword={fetchKeyword} />

      {/* CONTENT */}

      {
        newsArticles?.length > 0 ? 
        <>
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2">
            {newsArticles.map((news, index) => {
              return (
                <NewsCard
                  title={news.title}
                  description={news.description}
                  isNews={true}
                  image={news.image}
                  id={news.id}
                  onDelete={deleteNewsHandler}
                  onEdit={editNewsHandler}
                />
              );
            })}
          </div> */}

         <NewsRanking initialCards={newsArticles?newsArticles:[]} onDelete={deleteNewsHandler} onEdit={editNewsHandler}/>
         {isLoading && <div className="flex justify-center">Loading more articles...</div>}
          {/* PAGINATION */}

          {/* <Pagination
            lim={limit}
            currPage={page}
            pages={totalPages}
            setLim={changeLimitHandler}
            setCurrPage={setPage}
            label={"Articles"}
            counts ={[5, 10, 15]}
          /> */}
        </> :
        <DataNotFound/>
      }

    </div>
  );
}

export default News;
