import React, { useEffect, useState } from 'react'
import { deleteMethod, getMethod, postMethod } from '../../utils/apiMethod';
import { toastError, toastSuccess } from '../../utils/Toasters';

function SelectVideoType({title, prodTypeDetails, fetchSelectedOption, optional }) {

      //search variables andd functions
      const [keyword, setKeyword] = useState("");
      const [callApi, setCallApi] = useState(false);

    const searchHandler = (e) => {
      console.log("Inside")
      setCallApi(prev => !prev)
    }

  // const ptDetails = prodTypeDetails;
  
    const [openDropdown, setOpenDropdown] = useState(false)
    // console.log("PROP PRODTYPE DETAILS: ", prodTypeDetails)
    const [selectedOption, setSelectedOption] = useState([])
    // console.log("SELECT OPT: ", selectedOption)
    const [openAddNew, setOpenAddNew] = useState(false)
    const [openEditExisting, setEditExisting] = useState(false)
    const [productTypes, setProductTypes] = useState([]);

    const [category, setCategory] = useState('')
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null)
    const [deletableProductTypes, setDeletableProductTypes] = useState([])

    const addCategoryHandler = (e) => {
      console.log("HELLO")
      // setLoading(true);
  
        // checking of the values changed in field
  
      var raw = JSON.stringify({
        "name" : category,
        "icon" : "-"
      })
      
        postMethod(`product/addProductType`, raw)
      
          .then((res) => {
            if (res.status == true) {
              console.log("RESPONSE : ", res.data)
              setOpenAddNew(false);
              setOpenDropdown(true);
              setCategory('')
           
            }
           
          })
          .catch((error) => {
            // setLoading(false);
            // toastError(error.message);
            console.log("error.message: ", error.message)
          });
      
    };

    const deleteCategoryHandler = () => {
      // setDelete(false);
      deleteMethod(`product/deleteProductType/${categoryIdToDelete}`)
        .then((res) => {
          console.log("RESPONSE: ", res)
          if (res.status == true) {
          //   setOpen(false);
            setProductTypes(productTypes.filter((productType) => productType.id != categoryIdToDelete))
            setEditExisting(false);
            setOpenDropdown(false); setOpenAddNew(true); 
            toastSuccess(res.message);
          }
          else{
            toastError(res.message)
          }
        })
        .catch((error) => {
          toastError(error.message);
          console.log("Error: ",error.message);
        });
    };

    useEffect(() =>{
      console.log("prodTypeDetails: ",prodTypeDetails)
      if(prodTypeDetails){
        setSelectedOption(prodTypeDetails)
      }
      else{
        setSelectedOption([])
      }
    },[prodTypeDetails])

    useEffect(() => {
        // setLoading(true);
        getMethod(`content/getContentTypes?keyword=${keyword}`)
          .then((res) => {
            if(res?.status){
                setProductTypes(res.data)
                const deletable = res.data?.filter((type)=> {
                  return type.companyId !== 0;
                })
                setDeletableProductTypes(deletable)
            }
            if (res.status == 402) {
            //   router.push("/Subscription");
            console.log("res.status = 402")
            }
          })
          .catch((error) => {
            // setLoading(false);
            // toastError(error.message);
            console.log("error.message: ", error.message)
          });
      }, [openDropdown, callApi]);

          return (
            <div className="flex flex-col gap-4 w-full">
          
                <div className="relative flex flex-col gap-4">
                  <div className="font-light capitalize">{title} Type <span className="text-orange-1">*</span></div>
                      <div className="flex items-center justify-between rounded-lg border
                      border-grey-7 h-12 hover:cursor-pointer overflow-x-auto px-3 hide-scrollbar" onClick={() => {
                        if(openAddNew){
                          setOpenAddNew(false);
                          setOpenDropdown(false);
                        }else{
                          setOpenDropdown(!openDropdown); 
                        }
                        }}>
                      
                        {
                          selectedOption?.name  ?
        
                        (  <div className='flex flex-shrink-0 gap-1'>
        
                        
        
                                                  <div className='flex gap-1 bg-light-sea-green rounded-md items-center justify-center p-1'>
                                                      <div className='bg-sea-green rounded-full flex justify-center items-center w-5 h-5'>
                                                        <img src={selectedOption.icon === "-" || selectedOption.icon ==="" ?"assets/search-all-dropdown.svg":selectedOption.icon} alt="" className='w-7/12 m-auto' />
                                                      </div>
                                                      <div className='text-sea-green pe-1'>
                                                        {selectedOption.name?selectedOption.name:""}
                                                      </div>
                                                      
                                                     
                                                  
                                                  </div>
        
                                              
                          </div>
                          )
                          : (
                              <input className="focus:border-transparent 
                                outline-none mx-5 w-full h-full hover:cursor-pointer"
                                placeholder="Pick a Content Category"
                                required = {!optional} />
                            )
                          
                        }
                      
                        <div className="p-2">
                          <img src="assets/arrow-down.svg" />
                        </div>
                  </div>
        
                  {
                    openDropdown &&(
                      <div className="absolute top-[6rem] bg-white box-shadow2
                      rounded-md flex flex-col gap-5 font-500 p-5 w-full" style={{zIndex:"1000"}}>
                            <>
                                
                                <div className="flex items-center rounded-lg border border-grey-7 h-9 pr-2">
                                    <input
                                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                                        placeholder="Find an existing category"
                                        value={keyword}
                                        onChange={e=> setKeyword(e.target.value)}
                                    />
                                    <div role="button" onClick={searchHandler}>
                                        <img src="assets/search.svg" alt="search"/>
                                    </div>
                                </div>
                            </>

                            <div className='h-28 overflow-y-scroll'>

                            {
                                productTypes.map((option,index)=>{
                                    return(
                                    <div key={index} className="hover:cursor-pointer flex gap-2 items-center" 
                                        onClick={()=>{
                                        
                                        
                                          setSelectedOption(option);
                                         
                                       
                                        
                                            fetchSelectedOption(option)
                                            setOpenDropdown(false)
                                        }}>
                                        
                                    <div className='flex rounded-full p-1.5 bg-sea-green'>
                                        <img src="assets/search-all-dropdown.svg" />
                                    </div>
                                    <div>
                                            {option.name}
                                    </div>
                                    </div>
                                    )
                                })
                                }

                            </div>
        
                              
                                {/* <div className='flex flex-col gap-1'>
        
                                    <div role='button' className='bg-sea-green text-white text-sm p-2 rounded-md text-center'
                                    onClick={() => {setOpenDropdown(false); setOpenAddNew(true);}}>
        
                                        + Add new
        
                                    </div>
                                    <div role='button' className='bg-white border border-sea-green text-sea-green 
                                    text-sm p-2 rounded-md text-center'
                                    onClick={() => {setOpenDropdown(false); setEditExisting(true);}}
                                    >
        
                                        Edit existing
        
                                    </div>
                                </div> */}
                      </div>
                    )
                  }
        
                  {
                    openAddNew && (
        
                        <div className="absolute top-[6rem] bg-white box-shadow2
                      rounded-md flex flex-col gap-5 font-500 p-5 w-full">
                              <div className='text-black-1 font-semibold text-xl'>
                                  Add new category
                              </div>
        
                              <div className='flex gap-2 items-center'>
        
                                <div className='rounded-full bg-sea-green p-2 h-12 w-12 flex items-center justify-center'>
                                    <img src="assets/search-all-dropdown.svg" />
                                </div>
                                <div className='flex items-center border border-sea-green text-sea-green text-sm rounded-md px-8 py-1 text-center'>
        
                                    Edit
        
                                </div>
        
                              </div>
                            
                                
                                <div className="flex items-center rounded-lg border-2 border-sea-green h-12 pr-2">
                                    <input
                                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                                        placeholder="add category"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                    <div role='button' onClick={() => setCategory('')} className='text-sea-green text-xl'>
                                        X
                                    </div>
                                </div>
                        
        
                              
                                <div className='flex flex-col gap-1'>
        
                                    <div role="button" 
                                    className='bg-sea-green text-white text-sm p-2 
                                    rounded-md text-center' onClick={addCategoryHandler}>
        
                                        Add
        
                                    </div>
                                
                                </div>
                      </div>
        
                    )
                  }
        
                  {
                    openEditExisting && (
        
                      <div className="absolute top-[6rem] bg-white box-shadow2
                      rounded-md flex flex-col gap-5 font-500 p-5 w-full">
                          <>
                              
                              <div className="flex items-center rounded-lg border border-grey-7 h-9 pr-2">
                                  <input
                                      className="focus:border-transparent outline-none mx-5 w-full h-full"
                                      placeholder="Find an existing category"
                                  />
                                  <div>
                                      <img src="assets/search.svg" alt="search"/>
                                  </div>
                              </div>
                          </>
        
                              {
                              deletableProductTypes.map((option,index)=>{
                                  return(
                                  <div key={index} className="hover:cursor-pointer flex gap-2 items-center" 
                                      onClick={()=>{
                                          setCategoryIdToDelete(option.id)
                                  }}>
                                    <div className={`rounded-full h-6 w-6 ${categoryIdToDelete == option.id ? "border-8 border-red" : "border-2 border-sea-green"}`}>
        
                                    </div>
                                  <div className='flex rounded-full p-1.5 bg-sea-green'>
                                      <img src="assets/search-all-dropdown.svg" />
                                  </div>
                                  <div>
                                          {option.name}
                                  </div>
                                  </div>
                                  )
                              })
                              }
                              <div className='flex flex-col gap-1'>
        
                                  <div role='button' className='bg-red text-white text-sm p-2 rounded-md text-center'
                                  onClick={deleteCategoryHandler}>
        
                                      Remove
        
                                  </div>
                                  <div role='button' className='bg-white border border-sea-green text-sea-green 
                                  text-sm p-2 rounded-md text-center'
                                  onClick={() => {setOpenDropdown(false); setEditExisting(false);console.log("CANCEL CLICKED")}}
                                  >
        
                                      Cancel
        
                                  </div>
                              </div>
                    </div>
        
                    )
                  }
        
                </div>
              
              
            </div>
          )
      }

export default SelectVideoType
