import React, { useState, useRef } from "react";

function ImageUpload({ thumbnail, setThumbnail, label, required }) {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setThumbnail(selectedFile);
      console.log("Selected file:", selectedFile);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      setThumbnail(file);
      console.log("Dropped file:", file);
    } else {
      alert("Only image files are allowed.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        {label} {required && <span className="text-orange-1">*</span>}
      </div>
      <div
        className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28 cursor-pointer"
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="m-auto h-full w-full flex items-center justify-center">
          {thumbnail ? (
            <img
              src={thumbnail instanceof File ? URL.createObjectURL(thumbnail) : thumbnail}
              alt="Uploaded File"
              className="object-contain h-full w-auto rounded-2xl"
            />
          ) : (
            <img src="assets/documentupload.svg" alt="upload" className="m-auto" />
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
