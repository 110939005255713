import { getOnBoardCookie } from "../auth/cookies";
const url = process.env.REACT_APP_BASE_URL
// const url = "https://api.dev.futrconnect.com/v1/"
// const url = "http://44.199.133.157:8090/v1/"
export function bearerToken () {
    // const token = getToken()
    const token = getOnBoardCookie()
    return token
}

export const responseChecker = async(response) => {
    if(response.ok){
        const res = await response.json()
        if (Array.isArray(res.data)) {
            return {status: true, data: [...res.data ]}
        } else if (typeof res.data === 'object') {
            return {status: true, data: res.data}
        } else {
            console.log('response.data is neither an array nor an object');
        }
    }
    else if(response.status === 401){
        return {status: false, code:401, message: "Session Expired."}
    }
    else if(response.status === 400){
        const res = await response.json()
        return {status: false, code:400, message: res}
    }
    else{
        //  Will do it later
        return {status: false, code:response.status, message: "Something went wrong."}
    }
}

export const LoginQuery = async (token) =>{
    console.log("LOGIN QUERY : ", token)
    // const token = bearerToken()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    try{
        const response = await fetch(url+"auth/login", requestOptions)
        return response
        
    }catch(e){
        console.log(e)
    }
}