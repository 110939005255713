import React from 'react'

function DataNotFound() {
  return (
    <div className="m-auto my-10">
    <img src="/assets/data-not-found.png" alt="data not found"/>
  </div>
  )
}

export default DataNotFound