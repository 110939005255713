import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getCompanyCookie, getOnBoardCookie, setOnBoardCookie } from "../../../auth/cookies";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { StateContext } from "../../../Context/StateContext";
import { toastError } from "../../../utils/Toasters";

function Password({onEditClick}) {

  const navigate = useNavigate()

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isOldPassCorrect, setIsOldPassCorrect] = useState("");
    const [email, setEmail] = useState("");

    const { changePassword, signInWithEmailAndPassword } = useFirebaseAuth();

   


    const checkOldPassword = async () => {

      if(oldPassword === newPassword){
        toastError("New password same as old password. Try something new!")
      }else{

        const token = getOnBoardCookie()
  
        function parseJwt() {
            if (!token) {
              return;
            }
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace("-", "+").replace("_", "/");
            return JSON.parse(window.atob(base64));
          }
          var user = parseJwt();
  
        try {
          const response = await signInWithEmailAndPassword(user.email, oldPassword);
          console.log("RES PWD: ", response);
          if (response.user) {
            const newToken = response.user.multiFactor.user.accessToken;
            setOnBoardCookie(newToken);
    
            setIsOldPassCorrect(true);
            if (confirmPassword === newPassword) {
             const response =   await changePassword(newPassword);
             if(response){
               
               setOldPassword("");
               setNewPassword("");
               setConfirmPassword("")
               navigate("/company?activePage=dashboard")
             }
            } else {
              toastError("New password and Confirm password do not match!");
            }
          }
        } catch (error) {
          setIsOldPassCorrect(false);
          if (error.message == "Firebase: Error (auth/user-not-found).") {
            toastError("User Not Found", {
              toastId: "1",
            });
          } else if (error.message == "Firebase: Error (auth/wrong-password).") {
            toastError("Password Invalid", {
              toastId: "1",
            });
          } else if (
            error.message ==
            "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
          ) {
            toastError("Invalid Email", {
              toastId: "1",
            });
          } else if (
            error.message ==
            "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
          ) {
            toastError("Incorrect Password", {
              toastId: "1",
            });
          } else {
            var code = error.code.substring(error.code.lastIndexOf("/") + 1);
            var newcode = code.replaceAll("-", " ");
            toastError(newcode, {
              toastId: "2",
            });
          }
        }
      }

      };

    const submitHandler = () => {

    if (oldPassword?.length > 0) {
        checkOldPassword();
      } 
    }
    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    return (
      <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
           {/* header */}
           <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
  
                  <div className="flex flex-col">
                      <div className="font-medium text-[28px]">
                          Password
                      </div>
                      <div className="text-grey-2 text-[16px]">
                          Change your password
                      </div>
                  
                  
                  </div>
                  <div className="flex lg:justify-end mt-5 lg:mt-0">
                      <button className="btn-sea-green-thick" onClick={submitHandler}>
                     Change password
                      </button>
                  </div>
  
          </div>

          {/* Body */}
          <div className="flex flex-col gap-7">

                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        Old password
                    </div>
                    <div className="flex items-center rounded-lg border border-grey-7 h-12">
                        <input 
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        type="text"
                         value={oldPassword}
                         onChange={(e) => {
                           setOldPassword(e.target.value);
                           setIsOldPassCorrect(true);
                         }}
                        />
                    </div>
                    {isOldPassCorrect === "" || isOldPassCorrect ? (
                    ""
                  ) : (
                    <p className="text-red m-0">Password is incorrect.</p>
                  )}

                </div>
                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        New password
                    </div>
                    <div className={`flex items-center rounded-lg border border-grey-7 h-12 ${
                         oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                    }`}>
                        <input className={`focus:border-transparent outline-none mx-5 w-full h-full
                        ${
                            oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                        }`}
                        type="text"
                        value={newPassword}
                        readOnly={!(oldPassword.trim().length > 0)}
                        onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>

                </div>
                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        Confirm password
                    </div>
                    <div className={`flex items-center rounded-lg border border-grey-7 h-12
                    ${
                        oldPassword.trim().length > 0
                     ? ""
                     : "bg-grey-5 cursor-disabled"
                    }`}>
                        <input 
                        className={`focus:border-transparent outline-none mx-5 w-full h-full
                        ${
                            oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                        }`}
                        value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                </div>

          </div>
      </div>
    );
  }
  
  export default Password;