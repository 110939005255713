import React, { useEffect, useState, useContext } from "react";
import { ContentVideoContext } from "../../Context/ContentVideoContext";

function DropDownInput({ fetchSelectedOption , value , placeholder, width}) {
  console.log("value : ", value)
  const {videoRemark} =  useContext(ContentVideoContext)
  const [selectedOption, setSelectedOption] = useState(videoRemark?.value);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
     if(videoRemark.value){
      setSelectedOption(videoRemark.value);
      console.log("videoRemark.value : ", videoRemark?.value)
      if(videoRemark?.value?.length > 0){

        fetchSelectedOption(videoRemark?.value)
      }
      fetchSelectedOption(selectedOption)
     } 
  }, [videoRemark])

  const toSentenceCase = (string) => {
    return string.replace(/([A-Z])/g, ' $1') 
    .replace(/^./, str => str.toUpperCase())
  }
  return (
    <div className={`flex flex-col gap-4 ${width?width:"w-1/3"}`}>
      <div className="relative flex flex-col gap-4">
        <div className="font-light capitalize">
          Mark video as (optional)
        </div>
        <div
          className="flex items-center justify-between rounded-lg border
          border-grey-7 h-12 hover:cursor-pointer overflow-x-auto px-3 hide-scrollbar"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <input
            className="focus:border-transparent 
                    outline-none mx-5 w-full h-full hover:cursor-pointer"
            value={toSentenceCase(selectedOption === 'videoCall' ? "First Call Video" : selectedOption)}
            placeholder={placeholder ?placeholder :"Select Product Type"}
          />

          <div className="p-2">
            <img src="assets/arrow-down.svg" />
          </div>
        </div>

        {openDropdown && (
          <div
            className="absolute top-[6rem] bg-white box-shadow2
          rounded-md flex flex-col gap-5 font-500 p-5 w-full"
          >
            <div
              className="hover:cursor-pointer flex gap-2 items-center"
              onClick={() => {
                setSelectedOption("featuredVideo");
                fetchSelectedOption("featuredVideo");
                setOpenDropdown(false);
              }}
            >
              <div className="flex rounded-full p-1.5 bg-sea-green">
                <img src="assets/search-all-dropdown.svg" />
              </div>
              <div>Featured Video</div>
            </div>
            <div
              className="hover:cursor-pointer flex gap-2 items-center"
              onClick={() => {
                setSelectedOption("videoCall");
                fetchSelectedOption("videoCall");
                setOpenDropdown(false);
              }}
            >
              <div className="flex rounded-full p-1.5 bg-sea-green">
                <img src="assets/search-all-dropdown.svg" />
              </div>
              <div>First Call Video</div>
            </div>
            <div
              className="hover:cursor-pointer flex gap-2 items-center"
              onClick={() => {
                setSelectedOption("demoVideo");
                fetchSelectedOption("demoVideo");
                setOpenDropdown(false);
              }}
            >
              <div className="flex rounded-full p-1.5 bg-sea-green">
                <img src="assets/search-all-dropdown.svg" />
              </div>
              <div>Demo Video</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropDownInput;
