import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useFirebaseAuth from "../../auth/useFirebaseAuth";
import { removeOnBoardCookie, setOnBoardCookie } from "../../auth/cookies";
import EmailVerificationModal from "../general/modals/EmailVerificationModal";

import { toastError, toastSuccess } from "../../utils/Toasters";
import { ToastContainer } from "react-toastify";
import { StateContext } from "../../Context/StateContext";
import { getMethodTokenless } from "../../utils/apiMethod";
import Footer from "../general/NewFooter";

import GoogleSignUpModal from "../general/modals/GoogleSignUpModal";

import SignUpWithLinkedIn from "../general/SignUpWithLinkedIn";

function RegistrationForm() {
  console.log("env: ", process.env.REACT_APP_PUBLIC_FIREBASE_DOMAIN);

  const { setLoading } = useContext(StateContext);

  const url = process.env.REACT_APP_BASE_URL;
  // const url = "https://api.dev.futrconnect.com/v1/"
  // const url = "http://44.199.133.157:8090/v1/"
  const navigate = useNavigate();
  const {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
  } = useFirebaseAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [user, setUser] = useState("");
  const [credentials, setCredentials] = useState({
    fullName: "",
    companyName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  const [companyTypeList, setCompanyTypeList] = useState(null);
  const [companyType, setCompanyType] = useState("");
  const [isCompanyTypePresent, setIsCompanyTypePresent] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  function handleModalBtnClick() {
    signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((authUser) => {
        if (authUser.user.emailVerified) {
          // handleRegisterApiCall(authUser);
          navigate("/")
        } else {
          toastError("Please verify the link sent on email to proceed");
          signOut()
            .then(() => {
              console.log("user logged out");
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  function handleRegisterApiCall(userCredential) {
    console.log(userCredential?.user.displayName);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + userCredential.user.multiFactor.user.accessToken
    );

    var raw = JSON.stringify({
      name: credentials.companyName,
      ownerName: credentials.fullName,
      companyTypeId: companyType,
      //   email: credentials.email ,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url + "auth/register", requestOptions)
      .then((response) => {
        // setLoading(false);
        return response.json();
      })
      .then((result) => {
        console.log("RESULT: ", result);
        if (result.status) {
          removeOnBoardCookie();
          setOnBoardCookie(userCredential.user.multiFactor.user.accessToken);

          console.log("Registration successful:", userCredential.user);
          toastSuccess("Registration successful");

          userCredential.user.sendEmailVerification();
          setShowEmailVerificationModal(true)
          // navigate("/company");
          setLoading(false)
        } else {
          console.log("Error: ", result);
          toastError(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        toastError(error.message);
      });
  }

  const handleRegistration = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (credentials.password === credentials.confirmPassword) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          credentials.email,
          credentials.password
        );

        if (userCredential) {
          setLoading(false);
          setUser(userCredential);
          console.log(userCredential.user);
          // setShowEmailVerificationModal(true);

          // store isVerified value
          // const isUserVerified = userCredential.user.isVerified

          // userCredential.user.sendEmailVerification();

          await handleRegisterApiCall(userCredential);
        } else {
          setLoading(false);
          console.log("User Credential not set");
          toastError("User Credential not set");
        }

        // Send verification code to the phone number
        // await handleSendVerificationCode();
      } catch (error) {
        setLoading(false);
        console.log("Registration failed:", error.message);
        toastError(error.message);
      }
    } else {
      setLoading(false);
      console.log("Passwords do not match!");
      toastError("Passwords do not match!");
    }
  };

  useEffect(() => {
    setLoading(true);
    getMethodTokenless(`auth/getCompanyTypes`)
      .then((res) => {
        setLoading(false);

        if (res?.status) {
          setCompanyTypeList(res?.types);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="flex-col gap-10 mt-10">
      {/* Header */}
      <div className="flex justify-center">
        <img src="assets/FutrLogo.png" className="w-1/2 sm:w-1/4 md:w-1/6" />
      </div>

      {/* Body */}
      <div className="xs:flex-col xs:gap-10 lg:flex gap-20 px-10 md:px-20">
        {/* form start */}

        <div className="flex flex-col xs:w-full lg:w-1/3 gap-5 md:gap-8">
          <div className="text-center lg:text-start text-aqua-1 text-5xl md:text-[85px] font-extrabold leading-[85px]">
            Join Now
          </div>

          {/* input fields */}

          <form
            className="flex flex-col gap-5"
            onSubmit={(e) => handleRegistration(e)}
          >
            {/* Full Name */}

            <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 13H19V11H14V13ZM14 10H19V8H14V10ZM5 16H13V15.45C13 14.7 12.6333 14.1043 11.9 13.663C11.1667 13.2217 10.2 13.0007 9 13C7.8 13 6.83333 13.221 6.1 13.663C5.36667 14.105 5 14.7007 5 15.45V16ZM9 12C9.55 12 10.021 11.8043 10.413 11.413C10.805 11.0217 11.0007 10.5507 11 10C11 9.45 10.8043 8.97933 10.413 8.588C10.0217 8.19667 9.55067 8.00067 9 8C8.45 8 7.97933 8.196 7.588 8.588C7.19667 8.98 7.00067 9.45067 7 10C7 10.55 7.196 11.021 7.588 11.413C7.98 11.805 8.45067 12.0007 9 12ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM4 18H20V6H4V18Z"
                  fill="#1CBAAB"
                />
              </svg>
              <input
                className="outline-none placeholder-grey-7 text-xl w-full h-full bg-current"
                placeholder="Full Name"
                name="fullName"
                required={true}
                value={credentials.fullName}
                onChange={handleInputChange}
              />
            </div>
            {/* Company Name */}

            <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 13H19V11H14V13ZM14 10H19V8H14V10ZM5 16H13V15.45C13 14.7 12.6333 14.1043 11.9 13.663C11.1667 13.2217 10.2 13.0007 9 13C7.8 13 6.83333 13.221 6.1 13.663C5.36667 14.105 5 14.7007 5 15.45V16ZM9 12C9.55 12 10.021 11.8043 10.413 11.413C10.805 11.0217 11.0007 10.5507 11 10C11 9.45 10.8043 8.97933 10.413 8.588C10.0217 8.19667 9.55067 8.00067 9 8C8.45 8 7.97933 8.196 7.588 8.588C7.19667 8.98 7.00067 9.45067 7 10C7 10.55 7.196 11.021 7.588 11.413C7.98 11.805 8.45067 12.0007 9 12ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM4 18H20V6H4V18Z"
                  fill="#1CBAAB"
                />
              </svg>

              <input
                className="outline-none placeholder-grey-7 text-xl w-full h-full"
                placeholder="Company Name"
                name="companyName"
                required={true}
                value={credentials.companyName}
                onChange={handleInputChange}
              />
            </div>
            {/* Company Type  */}

            <div>
              <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 7H7M6 10H7M11 10H12M11 13H12M6 13H7M11 7H12M7 21V18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V21H7ZM7 21H3V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H13.4C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V9M19.7 13.5C19.7 14.3284 19.0284 15 18.2 15C17.3716 15 16.7 14.3284 16.7 13.5C16.7 12.6716 17.3716 12 18.2 12C19.0284 12 19.7 12.6716 19.7 13.5ZM21.5 21V20.5C21.5 19.1193 20.3807 18 19 18H17.5C16.1193 18 15 19.1193 15 20.5V21H21.5Z"
                    stroke="#1CBAAB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <select
                  className="login-form-input-field"
                  placeholder="Company Type"
                  name="companyType"
                  required={true}
                  value={companyType}
                  onChange={(e) => {
                    setCompanyType(e.target.value);
                    setIsCompanyTypePresent(null);
                  }}
                >
                  <option className="text-grey-14" value="">
                    Select an option
                  </option>
                  {companyTypeList?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* 
                                {
                                 isCompanyTypePresent === false && <p className="text-red pl-10 m-0">Please select a company type!</p>
                               } */}
            </div>

            {/* Email */}

            <>
              <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z"
                    fill="#1CBAAB"
                  />
                </svg>
                <input
                  className="outline-none placeholder-grey-7 text-xl w-full h-full"
                  placeholder="Enter Email"
                  name="email"
                  required={true}
                  value={credentials.email}
                  onChange={handleInputChange}
                />
              </div>

              {/* Password */}
              <div className="border-[1.5px] border-grey-14 w-full p-3 rounded-2xl pl-5 flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V10C4 9.45 4.196 8.97933 4.588 8.588C4.98 8.19667 5.45067 8.00067 6 8H7V6C7 4.61667 7.48767 3.43767 8.463 2.463C9.43833 1.48833 10.6173 1.00067 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.8043 13.9793 13.413 13.588C13.0217 13.1967 12.5507 13.0007 12 13C11.45 13 10.9793 13.196 10.588 13.588C10.1967 13.98 10.0007 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="#1CBAAB"
                  />
                </svg>
                <div className="border-r-[1.5px] p-1 md:p-2 w-3/4 border-r-grey-14 md:pl-10">
                  <input
                    className="outline-none placeholder-grey-7 text-xl w-full"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    required={true}
                    value={credentials.password}
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="m-auto font-400 text-sm md:text-xl text-grey-13 cursor-pointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </div>
              </div>

              {/* Confirm Password */}
              <div className="border-[1.5px] border-grey-14 w-full p-3 rounded-2xl flex gap-2 pl-5 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V10C4 9.45 4.196 8.97933 4.588 8.588C4.98 8.19667 5.45067 8.00067 6 8H7V6C7 4.61667 7.48767 3.43767 8.463 2.463C9.43833 1.48833 10.6173 1.00067 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.8043 13.9793 13.413 13.588C13.0217 13.1967 12.5507 13.0007 12 13C11.45 13 10.9793 13.196 10.588 13.588C10.1967 13.98 10.0007 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="#1CBAAB"
                  />
                </svg>
                <div className="border-r-[1.5px] p-1 md:p-2 w-3/4 border-r-grey-14 md:pl-10">
                  <input
                    className="outline-none placeholder-grey-7 text-xl w-full"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    required={true}
                    value={credentials.confirmPassword}
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="m-auto font-400 text-sm md:text-xl text-grey-13 cursor-pointer"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </div>
              </div>

              

                <button
                  type="submit"
                  className="w-full p-5 rounded-xl 
                                  flex justify-center bg-sea-green text-white 
                                  font-normal text-xl cursor-pointer border-none"
                >
                  Signup
                </button>
             

            </>

            {/*  Sign up with Google */}

            <div
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <div
                role="button"
                className="border-[1.5px]
     border-grey-14 w-full p-5 rounded-2xl flex 
    justify-center gap-3 items-center"
              >
                <div>
                  <img src="/assets/google-logo.svg" />
                </div>
                <div className="text-xl text-grey-10 font-base cursor-pointer">
                  Sign up with Google
                </div>
              </div>
            </div>

            {/*  Sign up with LinkedIn */}

            <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex justify-center gap-3 items-center">
              <div>
                <img src="assets/linkedin-logo.svg" />
              </div>
              <SignUpWithLinkedIn />
            </div>
          </form>

          <div className="text-center text-xl">
          Already have an account?{" "}
          <span
            className="text-sea-green font-bold cursor-pointer"
            onClick={() => navigate("/")}
          >
            Sign In
          </span>
        </div>
        </div>

      

        {/* form end */}

        {/* Image */}

        <div className="w-full md:w-2/3 flex mt-10 md:mt-0">
          <div
            className="w-full h-80 md:h-auto bg-cover bg-no-repeat bg-center"
            style={{ backgroundImage: `url('assets/form-image.png')` }}
          >
            {/* No content */}
          </div>
        </div>
      </div>

   
     


      {/* Email Verification Modal */}

      {showEmailVerificationModal && (
        <EmailVerificationModal
          onClickBackToLogin={handleModalBtnClick}
          onClickClose={() => setShowEmailVerificationModal(false)}
        />
      )}

      {openModal && (
        <GoogleSignUpModal
          companyTypeList={companyTypeList}
          onClose={setOpenModal}
        />
      )}
      <ToastContainer />

      {/* Footer */}
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
}

export default RegistrationForm;
