import React, { useEffect, useState } from 'react'

function TestRoute() {
    const fetchData = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/testCompanySetup`, requestOptions)
        const res = await response.text()
        console.log(res)
        setHtmlContent(res)
    }
    const [htmlContent, setHtmlContent] = useState('')
    useEffect(() => {

        fetchData()
       
      }, []);
    
      if(htmlContent.length == 0 ){

        return(<div>Loading...</div>)
        
      }else{
        return (
          <div>
      
           <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            
          </div>
        )
      }
 
}

export default TestRoute
