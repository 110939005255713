import { useEffect, useState } from "react";

function InputField({label, placeholder, width, optional, isDropdown, options, getSelectedValue, value, onChange, dropdownClasses, ...attributes}) {

  const [openDropdown, setOpenDropdown] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setOpenDropdown]);


  return (
    <div className={`flex flex-col gap-4 ${width ? `w-${width}` : "w-1/3"}`}>

      {
        isDropdown ? (
          <div className="relative flex flex-col gap-4">
            <div className="font-light capitalize">{label} {optional ? "" : (<span className="text-orange-1">*</span>)}</div>
            <div className="flex items-center justify-between rounded-lg border
                 border-grey-7 h-12 hover:cursor-pointer" onClick={() => setOpenDropdown(!openDropdown)}>

                  <input
                    className="focus:border-transparent outline-none mx-5 w-full h-full hover:cursor-pointer capitalize"
                    placeholder={placeholder}
                    value={value?value : (selectedOption?selectedOption:"")}
                    required={!optional}
                    
                  />
                  <div className="p-2">
                    <img src="assets/arrow-down.svg" />
                  </div>

            </div>

           
                {
                  openDropdown && (
                    <div className={`absolute top-[5.4rem] bg-white border h-28 overflow-y-scroll
                    border-grey-10 rounded-md flex flex-col gap-2 font-500 p-5 z-10 ${width?`w-${width}`:"w-full"}`}>

                      {
                        options.map((option,index)=>{
                          return(
                            <div key={index} className={`hover:cursor-pointer ${dropdownClasses}` }
                            onClick={()=>{
                              setSelectedOption(option);
                              getSelectedValue(option);
                              setOpenDropdown(false)

                              }}>
                              {option}
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }



          </div>
        )
          : (
            <>
              <div className="font-light">{label} {optional ? "" : 
              (<span className="text-orange-1">*</span>)}
            

            
              { attributes?.maxLength && <span className="text-grey-8" > (max {attributes?.maxLength} characters)</span>}
              </div>
              <div className={`flex items-center rounded-lg border border-grey-7 h-12 ${attributes?.cancelButton ? "justify-between pe-5" :""}`}>
                <input
                  className="focus:border-transparent outline-none mx-5 w-full h-full"
                  placeholder={placeholder}
                  value={value}
                  required={!optional}
                  onChange={onChange}
                  maxLength={attributes?.maxLength ? attributes?.maxLength : 1000}
                  type={attributes?.type ? attributes?.type : "text" }
                />
                {
                  attributes?.cancelButton &&
                  <div role="button" onClick={attributes?.cancelHandler}>
                    <img src="/assets/cancel.svg" />
                  </div>
                }
              </div>
            </>
          )
      }

    </div>
  );
}

export default InputField;
