import { React, useState } from "react";
import Footer from "./general/NewFooter";
import { postMethod } from "../utils/apiMethod";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../utils/Toasters";

function LandngPage() {
  const [userEmail, setUserEmail] = useState("");

  // Regex pattern for validating email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const submitHandler = (e) => {
    // setLoading(true);

    console.log("submit handler");

    var raw;

    if (
      userEmail === null ||
      userEmail === undefined ||
      userEmail.trim() === "" ||
      !emailPattern.test(userEmail)
    ) {
      toastError("Please enter a valid email address.");
      return;
    }

    raw = JSON.stringify({
      email: userEmail,
    });

    postMethod(`users/userNewsletterSubscription`, raw)
      .then((res) => {
        // setLoading(false);
        if (res.status == true) {
          //   setLoading(false);
          console.log("RESPONSE : ", res);

          toastSuccess(res.data);
        }
        // else{
        //   toastError(res.message)
        // }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  return (
    <div className="flex flex-col bg-white">
      {/* HEADER */}
      <div className="flex justify-center xl:justify-between m-auto w-10/12 py-10">
        <div className="w-1/2 md:w-1/3 xl:w-1/6 ">
          <img src="assets/FutrLogo.png" alt="brand-logo" />
        </div>
      </div>

      {/* WELCOME TO THE FUTR */}
      <div className="xs:flex xs:flex-col xl:flex m-auto">
        {/* form start */}

        <div className="flex flex-col w-10/12 m-auto xl:w-1/3 xl:ms-36 gap-8 justify-center items-center text-center xl:text-left">
          <div className="text-aqua-1 text-7xl font-bold md:leading-[85px]">
            Welcome to the futr
          </div>
          <div className="text-3xl text-dark-blue-1">
            <em>Soon to be the definitive tech search and showcase hub</em>
          </div>
          <div className="text-lg xl:text-xl text-dark-blue-1 leading-relaxed">
            The futr connect platform will transform the top of the scales
            funnelinto a self-service hub, saving time and money by bringing
            comprehensive tech solutions directly to buyers.
          </div>
        </div>

        {/* form end */}

        <div>
          <img src="assets/form-image.png" />
        </div>
      </div>

      {/* LAUNCH */}

      {/* LAUNCH */}
<div className="bg-dark-blue-1 py-28">
  <div className="w-8/12 flex flex-col gap-5 items-center text-center xl:text-start xl:items-start m-auto">
    <div className="text-yellow-1 font-bold tracking-wide text-3xl">
      We launch summer of 24
    </div>
    <div className="flex flex-col xl:flex-row gap-5 items-center xl:items-start">
      <div className="xs:w-full xl:w-1/3 text-white text-xl">
        Sign up and follow our social channels to stay up-to-date on our progress
      </div>

      <div className="flex flex-col xl:flex-row gap-5 w-full xl:w-2/3">
        <div className="border border-white flex-1 p-4 pl-10 rounded-full">
          <input
            className="outline-none placeholder-white text-xl text-white w-full h-full bg-dark-blue-1 rounded-full"
            placeholder="Email address"
            name="email"
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        <div
          role="button"
          className="flex-1 p-4 rounded-full flex justify-center items-center bg-sea-green text-white font-normal text-xl cursor-pointer"
          onClick={submitHandler}
        >
          Submit
        </div>
      </div>
    </div>
    <div className="flex gap-2">
      <div>
        <img width={70} src="/assets/linkedin-circle.svg" />
      </div>
      <div>
        <img width={70} src="/assets/instagram-circle.svg" />
      </div>
      <div>
        <img width={70} src="/assets/facebook-circle.svg" />
      </div>
    </div>
  </div>
</div>


      {/* WHY WE ARE BUILDING FUTR CONNECT */}

      <div className="flex flex-col justify-center items-center gap-10 py-32">
        <div className="text-sea-green text-3xl font-bold text-center">
          Why we're building futr connect:
        </div>
        <div className="w-8/12">
          <img className="w-full" src="/assets/launch-futr.png" />
        </div>
      </div>

      {/* BENEFITS */}

      <div className="bg-sea-green py-20">
        <div className="xs:flex xs:flex-col xs:items-center w-10/12 m-auto justify-center gap-4">
          <div className="text-white text-4xl font-bold md:text-2xl text-center md:text-left">futr benefits</div>
          <div className="xs:flex-col md:flex">

                   {/* Column -1 */}
            <div className="flex flex-col md:w-1/2 gap-5">
              
                <div className="text-dark-blue-1 font-bold text-4xl leading-[57px]">
                  For tech companies:
                </div>
              
              <div className="text-dark-blue-1 text-xl leading-relaxed md:w-3/4 font-normal">
                <ul className="list-disc pl-5">
                  <li>Lower cost of sales, and an increase in revenue</li>
                  <li>Creates new channels for new outcomes</li>
                  <li>Provides the experience clients desire</li>
                  <li>Controls the narrative - your message and brand</li>
                </ul>
              </div>
            </div>

                   {/* Column -2 */}
            <div className="flex flex-col md:w-1/2 gap-5">
              <div className="flex flex-col">
                <div className="text-dark-blue-1 font-bold text-4xl leading-[57px]">
                  For those that seek tech:
                </div>
              </div>
              <div className="text-dark-blue-1 text-xl leading-relaxed md:w-3/4">
                <ul className="list-disc pl-5">
                  <li> Time management with focus on priorities</li>
                  <li>24/7 access to curated, cutting-edge tech solutions</li>
                  <li>Self-service engagement model</li>
                  <li>Ability to match solutions based on use-case</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FUTR TV */}

      <div className="bg-gradient-to-b from-[#F5F5F5] to-[#EEF9FB] py-40">
        <div className="xs:flex-col items-center xl:flex mx-auto lg:w-10/12">
          <div className="flex flex-col m-auto text-center xl:text-start xs:items-center xl:items-start gap-5 w-5/6 justify-center">
            <div className="text-2xl text-dark-blue-1 leading-[48px]">
              As seen on futr.tv
            </div>

            <div className="text-4xl text-dark-blue-1 leading-normal font-bold">
              Startups, innovation, culture & the business of emerging tech
            </div>

            <div className="text-dark-blue-1 text-xl leading-9 xl:w-4/5">
              futr.tv focuses on startups, innovation, culture and the business
              of emerging tech with weekly video and audio podcasts where Chris
              Brandt and Sandesh Patel talk with industry leaders and deep
              thinkers.
            </div>
            <div className="p-5 rounded-full bg-sea-green text-white cursor-pointer
             text-xl xl:w-9/12 flex justify-center" role="button" onClick={() => { window.open('https://futr.tv', '_blank');}}>
              Check out futr tv
            </div>
          </div>

          <div>
            <img src="assets/futr-tv.png" className="w-full" />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-dark-blue-1 xs:flex-col items-center lg:flex lg:justify-between lg:px-40 py-10">
        <div className="flex-col lg:flex gap-10 items-center">
          <div className="m-auto flex justify-center">
            <img src="assets/FutrLogo.png" className="w-1/2 sm:w-1/3 lg:w-[292px]" />
          </div>

          <div className="text-white text-md lg:text-xl font-normal leading-[30px] flex justify-center">
            © 2023 All Rights Reserved Futr
          </div>
        </div>

        <div className="md:flex md:items-center md:gap-2 md:justify-center hidden ">
          <div>
            <img width={70} src="/assets/linkedin-circle.svg" />
          </div>
          <div>
            <img width={70} src="/assets/instagram-circle.svg" />
          </div>
          <div>
            <img width={70} src="/assets/facebook-circle.svg" />
          </div>
        </div>
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
}

export default LandngPage;
