import { createContext, useState } from "react";

export const ContentVideoContext = createContext();

const ContentVideoProvider = ({children}) => {

    const [videoSrc, setVideoSrc] = useState(false)
    const [videoRemark, setVideoRemark] = useState({ type:"btn1", status:false, value:"" })
    const [callTopContentApi, setCallTopContentApi] = useState(false)
    
    return <ContentVideoContext.Provider value={{videoSrc, setVideoSrc, videoRemark, setVideoRemark, callTopContentApi, setCallTopContentApi}}>{children}</ContentVideoContext.Provider> 
} 

export default ContentVideoProvider