import React, { useState } from "react";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { postMethod, postMethodTokenless } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";

function ForgotPassword({ onClickClose }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };

  const [email, setEmail] = useState("");
  const { forgotPassword } = useFirebaseAuth();

  const submitHandler = (e) => {
    e.preventDefault();

    let raw = JSON.stringify({
      email: email,
    });

    postMethodTokenless("auth/forgetPassword/company", raw)
      .then((res) => {
        onClickClose();

        if (res?.status === true) {
          forgotPassword(email)
            .then((result) => {
              // setLoading(false);
              toastSuccess("Email sent successfully!");
              console.log("Email sent successfully!");
            })
            
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        toastError(error);
      });

    // setLoading(true);
  };

  return (
    <div className="modal-wrapper">
      <form
        className="flex flex-col bg-white rounded-2xl w-1/3 p-10  gap-5"
        style={modalStyle}
        onSubmit={(e) => submitHandler(e)}
      >
        <div className="flex flex-row-reverse w-full">
          <div
            role="button"
            className="text-sea-green
         border border-sea-green px-5 py-3 rounded-md"
            onClick={onClickClose}
          >
            Close
          </div>
        </div>
        <div className="font-semibold text-2xl">Forgot password?</div>

        <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2 align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z"
              fill="#1CBAAB"
            />
          </svg>
          <input
            className="outline-none placeholder-grey-7 text-xl w-full h-full"
            placeholder="Enter your Email"
            type={"email"}
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="flex justify-center w-full">
          <button
            className={`text-white text-xl py-5 w-full rounded-xl ${
              email.length > 0 && email.includes("@") && email.includes(".")
                ? "bg-sea-green"
                : "bg-grey-7"
            }`}
            type="submit"
          >
            Send verification link
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
