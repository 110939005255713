import { ContentVideoContext } from "../../../Context/ContentVideoContext";
import InputField from "../../general/NewInputField";
import React, { useContext, useEffect, useRef, useState } from 'react';
import SelectProductTypeModal from "../../general/SelectProductType";
import DropDownInput from "../../general/DropDownInput";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import { postFormMethod, postMethod } from "../../../utils/apiMethod";
import VideoPlayer from "./VideoPlayer";
import SelectVideoType from "../../general/SelectVideoType";

function ContentAdd({ onBackClick }) {

    // const [video, setVideo] = useState(videoSrc);
   // const videoRef = useRef(null);
  // const handlePlayClick = () => {
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // };

  const { videoRemark, setVideoRemark } = useContext(ContentVideoContext);

  const {setLoading} = useContext(StateContext);
  const { videoSrc } = useContext(ContentVideoContext);

  const [videoTitle, setVideoTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoTypeId, setVideoTypeId] = useState("");
  const [videoMark, setVideoMark] = useState("");

  const fetchSelectedOption = (videoType) => {
    console.log("videoType: ", videoType?.id)
    setVideoTypeId(videoType?.id);
  };
  const fetchVideoMark = (videoMark) => {
    console.log("videoMark: ", videoMark)
    setVideoMark(videoMark);
  };


function getYouTubeVideoID(url) {
  const regex =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

const checkThumbnailExists = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      if (this.width > 120) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = url;
  });
};

const getYouTubeThumbnail = async (videoID) => {
  if (videoID) {
    const resolutions = [
      `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
      `https://img.youtube.com/vi/${videoID}/sddefault.jpg`,
      `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`,
      `https://img.youtube.com/vi/${videoID}/mqdefault.jpg`,
      `https://img.youtube.com/vi/${videoID}/default.jpg`,
    ];

    for (let url of resolutions) {
      const exists = await checkThumbnailExists(url);
      if (exists) {
        return url;
      }
    }
  }
  return null; // No thumbnail found
};

const submitHandler = async (e) => {
  setLoading(true);
  e.preventDefault();

  try {
    let thumbUrl = "";

    if (videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be")) {
      const videoId = getYouTubeVideoID(videoUrl);
      console.log("VIDEO ID:", videoId);

      thumbUrl = await getYouTubeThumbnail(videoId); // Wait for thumbnail URL
      console.log("THUMBNAIL URL:", thumbUrl);
      setThumbnailUrl(thumbUrl);
    } else if (videoUrl.includes("vimeo.com")) {
      const videoId = videoUrl.split(".com/")[1];
      thumbUrl = `https://vumbnail.com/${videoId}.jpg`;
    }

    console.log("Thumb URL before RAW:", thumbUrl);

    let raw = {
      title: videoTitle,
      contentTypeId: videoTypeId,
      poster: thumbUrl,
    };

    if (videoUrl) {
      raw = { ...raw, videoLink: videoUrl };
    }

    if (videoMark?.length > 0) {
      raw = { ...raw, markedVideo: videoMark };
    }

    raw = JSON.stringify(raw);

    const res = await postMethod(`content/addContent`, raw);
    setLoading(false);

    if (res?.status === true) {
      toastSuccess(res?.message);
      setVideoRemark({ type: "btn1", status: false, value: "" });
      onBackClick();
    } else {
      toastError(res.message);
    }
  } catch (error) {
    setLoading(false);
    toastError(error.message);
    console.log("error.message:", error.message);
  }
};

const BackBtnHandler = () => {
  setVideoRemark({ type: "btn1", status: false, value: "" });
  onBackClick();
}


  useEffect(() => {

    window.scrollTo(0,0)
     
  }, [])



  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/* Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={BackBtnHandler} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">
                Add Video
              </div>
              <div className="text-grey-2">Add  New Video Details</div>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="flex flex-col gap-5">
        {/* Video info */}
        <div className="form-outline-wrapper-div p-8 gap-8">
        
          {/* Input fields */}
         

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
          <InputField label="Video URL" 
            placeholder="Add Video URL"
            width={"full"}
             value={videoUrl} 
             onChange={ e => setVideoUrl(e.target.value)}
             optional = {false}
              />
            <InputField label="Video title" 
            placeholder="Title Your Video"
            width={"full"}
             value={videoTitle} 
             onChange={ e => setVideoTitle(e.target.value)}
             optional = {false}
              />

            
           
            <SelectVideoType title={"video"} 
            fetchSelectedOption={fetchSelectedOption}
             placeholder={"Pick a Content Category"}
             width={"w-full"}
             optional = {false}
             />
           
            <DropDownInput fetchSelectedOption={fetchVideoMark} value={videoRemark} placeholder={"Mark Video As"} width={"w-full"}/>
          </div>

          {/* <VideoPlayer/> */}
        </div>
      </div>

    
      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">Add Content</button>
        </div>
      </div>
    </form>
  );
}

export default ContentAdd;
