import React, { useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import LocalLoader from "../../general/LocalLoader";

function FeedbackModal({ onClickBackToLogin, closeHandler, id }) {
  console.log("ID OF FEEDBACK: ", id);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };

  const [feedback, setFeedback] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    getMethod(`company/viewFeedback/${id}`)
      .then((res) => {
       setLoading(false)
        if (res?.status) {
          console.log("feedback content : ", res.feedback);

          setFeedback({...res.feedback});
        }
      })
      .catch((error) => {
       setLoading(false)
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);


  

  return (
    <div className="modal-wrapper"  onClick={closeHandler}>
     
      <div
        className="flex flex-col bg-white rounded-2xl w-10/12 lg:w-3/6 p-5 lg:p-10  gap-5"
        style={modalStyle}
       
      >
        <div className="flex w-full justify-between items-center mb-5">
          <div className="text-2xl  capitalize font-semibold">
           User Feedback
          </div>

          <div
            role="button"
            className="text-sea-green
         border border-sea-green px-3 py-1 rounded-md"
            onClick={closeHandler}
          >
            X
          </div>
        </div>
           {
            loading ? <LocalLoader/> :
            <>
                 {/* Image and user details */}
        <div className="flex gap-2 items-center">
          <div className="">
            <img
              src={
                feedback.userFeedback?.profilePicUrl
                  ? feedback.userFeedback?.profilePicUrl
                  : "/assets/image-not-found.png"
              }
              className="rounded-lg w-20 h-20"
            />
          </div>

          <div className="flex flex-col gap-1 lg:w-2/3">
            <div className="font-semibold text-xl text-black-1 capitalize">
              {feedback.userFeedback?.name}
            </div>
            <div>{feedback.userFeedback?.id}</div>
          </div>
        </div>

        {/* Feedback title and description */}

        <div className="flex flex-col gap-2">

            <div className="capitalize text-xl text-[#686868] font-medium">
                {feedback?.title}
            </div>
            <div className="text-lg text-[#b0b0b0] font-normal">
                {feedback?.description}
            </div>


        </div>
         <div className="flex gap-2">


        {
          


          feedback?.feedbackImages?.map((item,idx)=> {
            return(
             
              <div key={idx} className="">
                <img
                  src={
                   item?.imageUrl
                  }
                  className="rounded-lg w-14 h-14"
                />
              </div>
            )
          })

        }

         </div>

      
            </>
        }
   

      </div>
    </div>
  );
}

export default FeedbackModal;
