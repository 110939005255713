import React, { useState } from "react";

function Pagination({ lim, currPage, pages, setLim, setCurrPage, label, counts }) {
  const [limit, setLimit] = useState(counts);

  const handlePrevPage = () => {
    if (currPage > 1) {
      setCurrPage(currPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currPage < pages) {
      setCurrPage(currPage + 1);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (value) => {
    setLim(value);
    setDropdownOpen(false);
  };


  return (
    <div className="flex justify-between">
      <div className="flex gap-2 items-center">
        <div className="text-sm">View</div>

        {/* <select
          className="border-none outline-none cursor-pointer text-sm"
          value={lim}
          onChange={(e) => setLim(e.target.value)}
        
        >
          {limit?.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select> */}
        <div className="relative">
          <div 
            className="border-none outline-none cursor-pointer text-sm p-2 bg-white border border-gray-300 rounded-md"
            onClick={handleDropdownToggle}
          >
            {lim}
          </div>
          {dropdownOpen && (
            <div className="absolute mt-1 bg-white rounded-md box-shadow1 z-10 px-5">
              {limit.map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer p-2 hover:bg-gray-100"
                  onClick={() => handleOptionSelect(item)}
                >
                  {item} 

                </div>
                
              ))}


            </div>
          )}
        </div>
        <span className={` cursor-pointer ${dropdownOpen ? "rotate-90": "-rotate-90"}`} role="button" onClick={handleDropdownToggle}>
<svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
            >
              <mask
                id="mask0_5566_2850"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="28"
                height="24"
              >
                <path d="M27.6512 0H0.394531V24H27.6512V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_5566_2850)">
                <path
                  d="M13.827 11.9998L17.0399 14.8278L15.434 16.2428L10.6152 11.9998L15.434 7.75684L17.0399 9.17184L13.827 11.9998Z"
                  fill="black"
                />
              </g>
            </svg>
                  </span>
        <div className="text-sm">{label} Per Page</div>
      </div>

      <div className="flex gap-2 gap-1 justify-center">
        {/* <img  src="assets/arrow-left-grey.svg" alt="arrow-left" /> */}
        <div className="flex items-center justify-center">
          <button onClick={handlePrevPage}>
            {/* <svg
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Frame" clip-path="url(#clip0_892_21867)">
                <path
                  id="Vector"
                  d="M13.828 11.9998L17.0408 14.8278L15.435 16.2428L10.6162 11.9998L15.435 7.75684L17.0408 9.17184L13.828 11.9998Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </g>
              <defs>
                <clipPath id="clip0_892_21867">
                  <rect
                    width="27.2567"
                    height="24"
                    fill="white"
                    transform="translate(0.394531)"
                  />
                </clipPath>
              </defs>
            </svg> */}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
            >
              <mask
                id="mask0_5566_2850"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="28"
                height="24"
              >
                <path d="M27.6512 0H0.394531V24H27.6512V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_5566_2850)">
                <path
                  d="M13.827 11.9998L17.0399 14.8278L15.434 16.2428L10.6152 11.9998L15.434 7.75684L17.0399 9.17184L13.827 11.9998Z"
                  fill="black"
                />
              </g>
            </svg>
          </button>
        </div>

        <div className="flex gap-1 items-center">
          {Array.from({ length: pages }, (_, index) => (
            <div
              key={index + 1}
              className={`text-xs cursor-pointer ${
                currPage === index + 1 ? "font-bold" : ""
              }`}
              onClick={() => setCurrPage(index + 1)}
            >
              {index + 1}
            </div>
          ))}
        </div>

        {/* <img onClick={handleNextPage} src="assets/arrow-right-black.svg" alt="arrow-left" /> */}
        <div className="flex items-center justify-center">
          <button onClick={handleNextPage}>
            <svg
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Frame" clip-path="url(#clip0_892_21870)">
                <path
                  id="Vector"
                  d="M14.5666 11.9998L11.3537 14.8278L12.9596 16.2428L17.7783 11.9998L12.9596 7.75684L11.3537 9.17184L14.5666 11.9998Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_892_21870">
                  <rect
                    width="27.2567"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 1 28 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
