import { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../general/NewInputField";
import { getMethod, postFormMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import ImageUpload from "../../general/ImageUpload";
function ReferencesEdit({ onBackClick, referenceId }) {
  const { setLoading } = useContext(StateContext);

  const [referenceDetails, setReferenceDetails] = useState({});
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");

  const [userPic, setUserPic] = useState(null);
  const userInputRef = useRef(null);

  // image functions
  const handleClick = () => {
    userInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setUserPic(selectedFile);
    // Do something with the selected file
  };

  const [companyPic, setCompanyPic] = useState(null);
  const companyInputRef = useRef(null);
  // image functions
  const handleCompanyPicClick = () => {
    companyInputRef.current.click(); // Trigger the file input dialog
  };

  const handleCompanyFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setCompanyPic(selectedFile);
    // Do something with the selected file
  };

  function validateUrl(url){

    try{

      let validate = new URL(url)
      const isLinkedIn = validate.hostname.includes('linkedin.com') && validate.pathname.startsWith('/in/');
    
    if (!isLinkedIn) {
      throw new Error();
    }

      return true

    }catch(error){
      throw new Error(`LinkedIn URL not valid!`)
    }


  }

  const submitHandler = (e) => {
    e.preventDefault();
   

    try{

      if(!userPic){
        toastError("Image is required")
      }else{
        setLoading(true);
  
        // checking of the values changed in field
  
      var formdata = new FormData();
      formdata.append("referenceId", referenceId);
  
      if (userPic !== referenceDetails.referralUserPicUrl) {
        formdata.append("userPic", userPic);
      }
      // if (companyPic !== referenceDetails.companyImageKeyUrl) {
      //   formdata.append("companyPic", companyPic);
      // }
      if (userName !== referenceDetails.userName) {
        formdata.append("userName", userName);
      }
  
      if (companyName !== referenceDetails.companyName) {
        formdata.append("companyName", companyName);
      }
      if (role !== referenceDetails.role) {
        formdata.append("role", role);
      }
      if (linkedinUrl !== referenceDetails.linkedinUrl) {
        validateUrl(linkedinUrl)
        formdata.append("linkedinUrl", linkedinUrl);
      }
      if (description?.trim() !== referenceDetails?.description) {
        formdata.append("description", description);
      }
  
      postFormMethod(`references/updateReference`, formdata)
        .then((res) => {
          setLoading(false);
          if (res.status === true) {
            setLoading(false);
            console.log("RESPONSE : ", res);
  
            toastSuccess(res.message);
            onBackClick();
          }
          // else{
          //   toastError(res.message)
          // }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
  
      }
  

    }catch(error){
      setLoading(false);
      toastError(error.message)
    }
    
  };

  useEffect(() => {
    setLoading(true);

    window.scrollTo(0, 0);

    getMethod(`references/viewReference/${referenceId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          console.log(res);
          setReferenceDetails({ ...res.data });
          setUserName(res.data.userName);
          setRole(res.data.role);
          setCompanyName(res.data.companyName);
          setLinkedinUrl(res?.data?.linkedinUrl);
          setUserPic(res.data.referralUserPicUrl);
          setCompanyPic(res.data.companyImageKeyUrl);
          setDescription(res.data?.description);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit reference</div>
              <div className="text-grey-2">Edit reference details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save Changes
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* COMPANY DETAILS */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* Logo input */}
          {/* <div className="flex flex-col gap-4">
            <div>Reference User Pic<span className="text-orange-1">*</span></div>
            <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28">
             {
              userPic ? (

                <img
                src={   userPic instanceof File
                    ? URL.createObjectURL(userPic)
                    : userPic} 
                alt="upload"
                className="flex items-center m-auto h-full w-full 
                rounded-2xl cursor-pointer"
                onClick={handleClick}
              />

              ) : (

                <img
                src="assets/documentupload.svg"
                alt="upload"
                className="m-auto cursor-pointer"
                onClick={handleClick}
              />

                
              )
             }

               <input
                  type="file"
                  ref={userInputRef}
                  style={{ display: "none" }} // Hide the file input
                  onChange={handleFileChange}
                  accept="image/*" // Accept only image files
                />
              
         
            </div>
          </div> */}

          <ImageUpload
            thumbnail={userPic}
            setThumbnail={setUserPic}
            label={"Reference User Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Reference User Name"
              placeholder="User Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="Role"
              placeholder="Add role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="Linkedin URL"
              placeholder="Add linkedin URL"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              width={"w-full"}
              optional={true}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-light">
               Reference Quote{" "}
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder={"Description"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              Company Name<span className="text-orange-1">*</span>
            </div>

            <div className="xs:flex-col lg:flex w-full gap-4">
              {/* <div className="flex items-center rounded-lg bg-light-sea-green-1 h-12 w-14">
              {
                companyPic ? (

                  <img
                  src={   companyPic instanceof File
                    ? URL.createObjectURL(companyPic)
                    : companyPic}
                  alt="upload"
                  className="flex items-center m-auto h-full
                   w-full rounded-2xl cursor-pointer"
                   onClick={handleCompanyPicClick}
                />

                ):(
                  <img
                  src="assets/documentupload.svg"
                  alt="upload"
                  className="m-auto h-5 w-5 cursor-pointer"
                  onClick={handleCompanyPicClick}
                />
                )
              }
                <input
                  type="file"
                  ref={companyInputRef}
                  style={{ display: "none" }} // Hide the file input
                  onChange={handleCompanyFileChange}
                  accept="image/*" // Accept only image files
                />
           
            </div> */}

              <div className="flex items-center rounded-lg border border-grey-7 h-12 w-full">
                <input
                  className="focus:border-transparent outline-none mx-5 w-full h-full"
                  placeholder="Company Name"
                  value={companyName}
                  required={true}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button
            className="btn-sea-green text-xl w-full"
            type="submit"
          >
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
}

export default ReferencesEdit;
