import React, { useState } from "react";
import { deleteMethod } from "../../../utils/apiMethod";
import DeleteConfirmation from "../modals/DeleteConfirmation";

function Products({
  image,
  category,
  title,
  description,
  isNews,
  id,
  onDelete,
  onEdit,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = () => {
    console.log("ID in product compo: ", id);
    setShowDeleteModal(true);

    // onDelete(id)
  };

  //   const [editProduct, setEditProduct] = useState(false)

  return (
    <div
      key={id}
      className={`flex flex-col gap-2 bg-white rounded-md p-4 product-card-shadow ${
        isNews ? "min-h-80" : ""
      } `}
      style={{width:"100%"}}
    >
      {/* image */}
      {isNews && (
        <div className="h-36">
          <img
            src={`${image ? image : "/assets/image-not-found.png"}`}
            alt={title}
            className="rounded-lg h-full w-full object-cover"
          />
        </div>
      )}

      {/* Description */}
      <div className="flex flex-col gap-2">
        <div className="text-black-1 font-semibold text-base overflow-hidden truncate w-full">
          {title}
        </div>

        {/* label */}
        {isNews ? (
          ""
        ) : (
          <div className="flex gap-1 overflow-x-auto overflow-y-hidden hide-scrollbar">
            {category && (
              <div className="flex items-center gap-1 px-1 py-1.5 bg-light-sea-green rounded-md whitespace-nowrap">
                {/* <div className="flex justify-center items-center rounded-full bg-white w-5 h-5">

                                                <img src="assets/podcast-icon.svg" alt="category" className="w-3"/>

                                            </div> */}
                <div className="text-sea-green text-sm w-3/4">{category}</div>
              </div>
            )}
          </div>
        )}
        <div
          className={`text-xs text-grey-10 max-w-prose overflow-hidden text-ellipsis ${
            isNews ? "line-clamp-4 h-[65px]" : "line-clamp-2 h-8"
          } `}
          style={{ overflow: "hidden" }}
          dangerouslySetInnerHTML={{ __html: description }}
        >
         
        </div>
      </div>

      {/* Action buttons */}
      <div className="flex items-center">
        <div className="w-3/4">
          <button
            className="bg-white border border-sea-green
                         text-sea-green font-semibold w-full p-1 rounded-md"
            onClick={() => onEdit(true, id)}
          >
            Edit
          </button>
        </div>
        <div
          role="button"
          onClick={handleDeleteClick}
          className="w-1/4 flex justify-center"
        >
          <img src="assets/trash-red.svg" alt="delete" />
        </div>
      </div>

      {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => onDelete(id)}
        />
      )}
    </div>
  );
}

export default Products;
