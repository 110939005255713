import { useContext, useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod";
import { setCompanyCookie } from "../../../auth/cookies";
import Cookies from "js-cookie";
import { StateContext } from "../../../Context/StateContext";
import { toastError } from "../../../utils/Toasters";
import { useNavigate } from "react-router";
import { FormatDate } from "../../../utils/dateFormat";

function CompanyProfileMain({ onEditClick, addContent }) {
  const navigate = useNavigate()
  const { setCompanyName, setCompanyLogo, setCompanyBio, loading, setLoading } =
    useContext(StateContext);

  const [companyDetails, setCompanyDetails] = useState({
    thumbnailUrl: "",
    thumbnailKey: "",
    companyName: "",
    ownerName: "",
    email: "",
    description: "",
    companyFounded: "",
    hqAddress: "",
    hqZipCode: "",
    hqState: "",
    phone: "",
    profileCompletePercent: "",
    videoPresence: {},
  });
  const [addresses, setAddresses] = useState(null);

  const handleAddContent = () => {
    // addContent();
    navigate('/company?activePage=content')
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0,0)
    getMethod(`company/getMyCompanyDetails`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          console.log(res);

          // set context variables

          setCompanyName(res?.company?.company?.name);
          setCompanyLogo(res?.company?.company?.thumbnailUrl);
          setCompanyBio(res?.company?.company?.description);

          // set state variables
          setCompanyDetails({
            thumbnailKey: res.company.company.thumbnailKey,
            thumbnailUrl: res.company.company.thumbnailUrl,
            companyName: res.company.company.name,
            ownerName: res.company.company.ownerName,
            email: res.company.company.email,
            description: res.company.company.description,
            companyFounded: res.company.company.companyFounded,
            // hqAddress:res.company.addresses[0].address,
            // hqZipCode:Cookies.get("hqZipCode"),
            // hqState:Cookies.get("hqState"),
            phone: res.company.company.phone,
            profileCompletePercent: res.company.company.profileCompletePercent,
            marketCategories: res?.company?.company?.companyMarketCats,
            meetingUrl: res?.company?.company?.meetingUrl,
            videoPresence: { ...res?.company?.videoPresence },
          });

          setAddresses(res.company?.addresses[0]);
        }
        if (res.status == 402) {
          //   router.push("/Subscription");
          console.log("res.status = 402");
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      {/*  Progress section */}
      <div className="flex flex-col gap-3 bg-white rounded-md p-8">
        <div className="flex flex-col gap-3 lg:w-5/12">
          <div className="font-semibold text-2xl">
            Complete Your Company Profile &nbsp;{" "}
            {/* percent count - desktop view */}
            <span className="hidden lg:inline-block text-sea-green text-[16px] font-normal">
              {companyDetails.profileCompletePercent} %
            </span>{" "}
          </div>

          <div className="flex items-center gap-5">
            {/* progress bar */}
            <div className="flex rounded-md h-2 w-2/3 lg:w-5/12 bg-light-sea-green">
              <div
                className="bg-sea-green rounded-md"
                style={{
                  width: `calc(${companyDetails.profileCompletePercent}/100 * 100%)`,
                }}
              ></div>

              {/* <div className="bg-light-sea-green rounded-e-md w-1/4">
                    </div> */}
            </div>
            {/* percent count - mobile view */}
            <div className="lg:hidden text-sea-green text-[16px] font-normal">
              {companyDetails.profileCompletePercent} %
            </div>{" "}
          </div>
        </div>
        <div className="text-black-1 text-sm lg:text-lg">
          Fill out the remaining fields to complete your profile
        </div>
        <div className="grid lg:grid-cols-3 items-center justify-center bg-[#F6FAFC] p-3 lg:px-6">
          <div className="flex gap-2 lg:gap-5 items-center text-black-1 lg:justify-center">
            {companyDetails?.videoPresence?.featuredVideo ? (
              <div>
                <img src="assets/check.svg" alt="" />
              </div>
            ) : (
              <div role="button" onClick={handleAddContent}>
                <img src="assets/plus.svg" alt="" />
              </div>
            )}

            <div>Featured video</div>
          </div>
          <div className="flex gap-2 lg:gap-5 items-center text-black-1 lg:justify-center">
            {companyDetails?.videoPresence?.demoVideo ? (
              <div>
                <img src="assets/check.svg" alt="" />
              </div>
            ) : (
              <div role="button" onClick={handleAddContent}>
                <img src="assets/plus.svg" alt="" />
              </div>
            )}
            <div>Demo video</div>
          </div>
          <div className="flex gap-2 lg:gap-5 items-center text-black-1 lg:justify-center">
            {companyDetails?.videoPresence?.videoCall ? (
              <div>
                <img src="assets/check.svg" alt="" />
              </div>
            ) : (
              <div role="button" onClick={handleAddContent}>
                <img src="assets/plus.svg" alt="" />
              </div>
            )}
            <div>First call video</div>
          </div>
          {/* <div className="flex gap-5 items-center text-black-1">

                        <div>
                            <img src="assets/plus.svg" alt=""/>
                        </div>
                        <div>
                            Verify email
                        </div>
                    </div> */}
        </div>
      </div>

      {/*  Company info */}
      <div className="flex flex-col gap-5 bg-white rounded-md p-8">
        {/* header */}
        <div className="xs:flex-col gap-10 lg:flex justify-between items-center">
          <div className="flex flex-col">
            <div className="font-medium text-[28px]">Company Profile</div>
            <div className="mt-3 lg:mt-0 text-grey-2">Basic info about your profile</div>
          </div>
          <div className="mt-5 lg:mt-0 flex lg:justify-end">
            <button className="btn-sea-green-thick" onClick={onEditClick}>
              Edit
            </button>
          </div>
        </div>

        {/* Details */}
        <div className="flex flex-col gap-5">
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Logo</div>
            <div className="lg:w-2/3">
              <div className="p-2 h-28 w-28">
                <img
                  src={
                    companyDetails.thumbnailUrl
                      ? companyDetails.thumbnailUrl
                      : "/assets/image-not-found.png"
                  }
                  alt="logo"
                  className="rounded-lg object-contain h-full w-auto"
                />
              </div>
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Name</div>
            <div className="lg:w-2/3 font-light">
              {companyDetails.ownerName ? companyDetails.ownerName : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Email</div>
            <div className="lg:w-2/3 font-light flex gap-5">
              <div>{companyDetails.email ? companyDetails.email : "-"}</div>
              {companyDetails.email && (
                <div className="lg:w-1/4 flex gap-2 items-center text-black-1">
                  <div>
                    <img src="assets/check.svg" alt="" />
                  </div>

                  <div>Verified</div>
                </div>
              )}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Description</div>
            <div className="lg:w-2/3 font-light">
              {companyDetails.description ? companyDetails.description : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Company Founded</div>
            <div className="lg:w-2/3 font-light">
              {companyDetails.companyFounded ? FormatDate(companyDetails.companyFounded) : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Meeting URL</div>
            <div className="lg:w-2/3 font-light">
              {companyDetails.meetingUrl ? companyDetails.meetingUrl : "-"}
            </div>
          </div>
          {/* <div className="flex items-center">

                    <div className="w-1/3 font-normal text-black-1">
                       Market Categories
                    </div>
                    <div className="w-2/3 font-light flex">
                    {companyDetails?.marketCategories?.length > 0 ? 
                        

                            companyDetails?.marketCategories?.map((category,index)=>{
                                console.log("CATEGORY : ", category)
                                return(
                                    <div key={index} className="capitalize">

{index == 0 ? "" : " " }{category?.marketCategoryDefined?.name}{index == companyDetails?.marketCategories?.length - 1 ? "" : " , " }

                                    </div>
                                )
                            })

                        
                     : "-"
                    }
                    </div>

                </div> */}

          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">
              Market Categories
            </div>
            <div className="lg:w-2/3 font-light flex flex-wrap">
              {companyDetails?.marketCategories?.length > 0
                ? companyDetails?.marketCategories?.map((category, index) => {
                    console.log("CATEGORY : ", category);
                    return (
                      <div key={index} className="capitalize">
                        {index > 0 && ", "}
                        {category?.marketCategoryDefined?.name}
                      </div>
                    );
                  })
                : "-"}
            </div>
          </div>

          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">HQ Address</div>
            <div className="lg:w-2/3 font-light">
              {addresses?.address ? addresses.address : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">
              HQ Zip/postal code
            </div>
            <div className="lg:w-2/3 font-light">
              {addresses?.zipCode ? addresses.zipCode : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">HQ State</div>
            <div className="lg:w-2/3 font-light">
              {addresses?.state ? addresses.state : "-"}
            </div>
          </div>
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-normal text-black-1">Mobile Number</div>
            <div className="lg:w-2/3 font-light flex gap-5">
              <div>{companyDetails.phone?.length > 1 ? companyDetails.phone : "-"}</div>
              {/* {companyDetails.phone?
                        (

                            <div className="w-1/4 flex gap-2 items-center text-black-1">

                            <div>
                                <img src="assets/check.svg" alt=""/>
                            </div>
                            <div>
                                Verified
                            </div>
                    </div>

                        ): <div></div>
                        }
                        */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileMain;
