import React, { useState } from 'react';

import 'react-quill/dist/quill.snow.css';

import ReactQuill from "react-quill";

export const TextEditor = ({ placeholder, value, setValue }) => {

    return (
        <ReactQuill
            theme="snow"
            onChange={newContent => setValue(newContent)}
            value={value}
            modules={{
                toolbar: [
                    [{ 'font': [] }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image', 'video'],
                    [{ 'color': [] }, { 'background': [] }], // Text color and background color
                    [{ 'align': [] }], // Text alignment
                    ['clean'],
                ],
                clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                },
            }}
            formats={[
              
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video', 'color', 'background', 'align'
            ]}
            placeholder={placeholder}
        />
    );
};
