import React from 'react'
import { PulseLoader} from 'react-spinners';

export default function LocalLoader(){
    return (
        <div style={{
            // width:"100vw",height:"100vh",
        // position:"fixed",top:"0",left:"0",
            // backgroundColor:"rgba(210, 210, 210, 0.6)",backdropFilter:"blur(15px)",
            zIndex:"9999"}} className={`flex justify-center items-center m-10`}
        >

          <PulseLoader size={15} color="#1CBAAB"></PulseLoader>

        </div>
    );
}

