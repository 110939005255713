import { useContext, useEffect, useState } from "react";
import {
  removeCompanyCookie,
  removeOnBoardCookie,
  removeToken,
} from "../../auth/cookies";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastInfo } from "../../utils/Toasters";
import cookies from "js-cookie";
import { StateContext } from "../../Context/StateContext";
import useFirebaseAuth from "../../auth/useFirebaseAuth";

function SidePanel({ fetchActiveCategory, currentCategory, activePage }) {
  const { signOut } = useFirebaseAuth();

  const [activeCategory, setActiveCategory] = useState(activePage);
  const { companyName, companyLogo, companyBio } = useContext(StateContext);

  console.log(" COMPANY LOGO : ", companyLogo , " COMPANY NAME : ", companyName , " COMPANY BIO : ", companyBio)

  const navigate = useNavigate();

  function handleLogout() {
    removeCompanyCookie();
    removeToken();
    removeOnBoardCookie();
    signOut();

    toastInfo("User logged out!");
    navigate("/");
  }
  const manageCategories = [
    {
      item: "Company Profile",
      icon: "office-building",
      value: "dashboard",
    },
    {
      item: "Products",
      icon: "products",
      value: "products",
    },
    {
      item: "People",
      icon: "people",
      value: "people",
    },
    {
      item: "Finance",
      icon: "finance",
      value: "finance",
    },
    {
      item: "Metrics",
      icon: "metrics",
      value: "metrics",
    },
    {
      item: "Content",
      icon: "content",
      value: "content",
    },
    {
      item: "News",
      icon: "news",
      value: "news",
    },
    {
      item: "References",
      icon: "references",
      value: "references",
    },
    {
      item: "Sources",
      icon: "sources",
      value: "sources",
    },
    {
      item: "Awards",
      icon: "awards",
      value: "awards",
    },
    {
      item: "Analytics",
      icon: "analytics",
      value: "analytics",
    },
    {
      item: "Feedback",
      icon: "feedback",
      value: "feedback",
    },
  ];
  const securityCategories = [
    {
      item: "Password",
      icon: "password",
      value: "password",
    },
    {
      item: "Privacy",
      icon: "privacy",
      value: "privacy",
    },
  ];

  useEffect(() => {
    setActiveCategory(activePage);
    
  }, []);

  console.log("active category : ", activeCategory);

  return (
    <div className="white-div pb-0 rounded-xl flex flex-col gap-5">
      {/* Company logo, Name, tagline section */}

      <div className="flex gap-3">
        <div className="w-5/12 h-20">
          <img
            src={companyLogo ? companyLogo : "/assets/image-not-found.png"}
            alt=""
            className=" rounded-xl object-contain w-auto h-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-7/12">
          <div className="text-[20px] font-bold max-w-prose line-clamp-1">
            {companyName}
          </div>
          <div className="text-grey-2 text-[12px] leading-4 max-w-prose line-clamp-2">
            {companyBio ? companyBio : "( Not added yet )"}
          </div>
        </div>
      </div>

      {/* Manage section */}

      <div className="flex flex-col gap-3">
        <div className="text-grey-3 text-lg">Manage</div>
        <div className="flex flex-col">
          {manageCategories.map((category, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate(`/company?activePage=${category.value}`)
                  setActiveCategory(category.value);
                  fetchActiveCategory(category.value);
                }}
                className={`flex gap-2 items-center rounded-lg text-sm px-2 py-3 cursor-pointer ${
                  activePage === category.value
                    ? "bg-sea-green text-white"
                    : "bg-none"
                } `}
              >
                <div>
                  <img
                    src={`assets/${category.icon}-${
                      activePage === category.value ? "white" : "black"
                    }.svg`}
                    alt={`${category.item}`}
                    className=""
                  />
                </div>
                <div>{category.item}</div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-grey-3 text-lg">Security</div>
          <div className="flex flex-col">
            {securityCategories.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(`/company?activePage=${category.value}`)
                    setActiveCategory(category.value);
                    fetchActiveCategory(category.value);
                  }}
                  className={`flex gap-2 items-center rounded-lg text-sm px-2 py-3 
                                        cursor-pointer ${
                                          activePage === category.value
                                            ? "bg-sea-green text-white"
                                            : "bg-none"
                                        }`}
                >
                  <div>
                    <img
                      src={`assets/${category.icon}-${
                        activePage === category.value? "white" : "black"
                      }.svg`}
                      alt={`${category.item}`}
                      className=""
                    />
                  </div>
                  <div>{category.item}</div>
                </div>
              );
            })}

            <div
              onClick={() => {
                setActiveCategory("logout");
                handleLogout();
              }}
              className={`flex gap-2 items-center rounded-lg text-red text-sm px-2 py-3 cursor-pointer ${
                currentCategory === "logout" ? "bg-sea-green" : "bg-none"
              } `}
            >
              <div>
                <img src="assets/logout.svg" alt="logout" className="" />
              </div>
              <div className="text-red">Log out</div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
}

export default SidePanel;
