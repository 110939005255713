import React from 'react'
import { useState, useRef } from "react";
import ReactPlayer from 'react-player';

export default function VideoUpload({ videoUrl, closeHandler }) {
    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex:1000,
     }

     const closeModal = () => {
       console.log("CLICKEDDD")
       closeHandler()
     }

  return (
    <div className='modal-wrapper cursor-pointer' onClick={closeModal}>

        <div className='flex flex-col' style={modalStyle}>

    

    
          <ReactPlayer url={videoUrl} controls playing width="640px" height="360px"/>
       
        </div>
    </div>
  )
}
