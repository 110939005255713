import { useContext, useEffect, useState } from "react";
import Executives from "../../general/cards/people/Executives";
import Advisors from "../../general/cards/people/Executives";
import BoardMembers from "../../general/cards/people/Executives";
import Founders from "../../general/cards/people/Founders";
import Investors from "../../general/cards/people/Investors";
import { deleteMethod, getMethod } from "../../../utils/apiMethod";
import Searchbar from "../../general/Searchbar";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import DataNotFound from "../../general/DataNotFound";
import PeopleRanking from "../../general/ranking/PeopleRanking";

function People({ onEditClick, fetchIsPeopleEdit }) {

  const { setLoading } = useContext(StateContext);

  const [peopleData, setPeopleData] = useState(null);
  const [foundersData, setFoundersData] = useState([]);
  const [executivesData, setExecutivesData] = useState([]);
  const [advisorsData, setAdvisorsData] = useState([]);
  const [investorsData, setInvestorsData] = useState([]);
  const [membersData, setMembersData] = useState([]);



  // searchbar functions
  const [keyword, setKeyword] = useState('')

  function fetchKeyword(keyword){

 
      setKeyword(keyword)


  }



  // other functions

  const [editPeople, setEditPeople] = useState({
    isEdit: false,
    productId: null,
  });

  const deleteFounderHandler = (peopleId) => {
    // setDelete(false);
    deleteMethod(`people/deletePeople/${peopleId}`)
      .then((res) => {
        if (res.status == true) {
     
          setFoundersData(
            foundersData?.filter((people) => people.id != peopleId)
          );
            toastSuccess(res.message);
        }
        else{
          toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  const deleteInvestorHandler = (peopleId) => {
    // setDelete(false);
    deleteMethod(`people/deletePeople/${peopleId}`)
      .then((res) => {
        if (res.status == true) {
     
          setInvestorsData(
            investorsData?.filter((people) => people.id != peopleId)
          );
            toastSuccess(res.message);
        }
        else{
          toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  const deleteExecutiveHandler = (peopleId) => {
    // setDelete(false);
    deleteMethod(`people/deletePeople/${peopleId}`)
      .then((res) => {
        if (res.status == true) {
          setExecutivesData(
            executivesData?.filter((people) => people.id != peopleId)
          );
            toastSuccess(res.message);
        }
        else{
          toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  const deleteAdvisorHandler = (peopleId) => {
    // setDelete(false);
    deleteMethod(`people/deletePeople/${peopleId}`)
      .then((res) => {
        if (res.status == true) {
          setAdvisorsData(
            advisorsData?.filter((people) => people.id != peopleId)
          );
            toastSuccess(res.message);
        }
        else{
          toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  const deleteMemberHandler = (peopleId) => {
    // setDelete(false);
    deleteMethod(`people/deletePeople/${peopleId}`)
      .then((res) => {
        if (res.status == true) {
          setMembersData(
            membersData?.filter((people) => people.id != peopleId)
          );
            toastSuccess(res.message);
        }
        else{
          toastError(res.message)
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };

  function editPeopleHandler(bool, id) {
    const obj = {
      isEdit: bool,
      peopleId: id,
    };
    setEditPeople({ ...obj });
    fetchIsPeopleEdit(obj);
  }

  useEffect(() => {
    setLoading(true);
    getMethod(`people/getMyPeople?keyword${keyword}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setPeopleData(res.data);

          const founders = res.data.find((data) => data.label == "Founders");

          if (founders) {
            const foundersPeople = founders.people;
            setFoundersData(foundersPeople);
          }
          const executives = res.data.find(
            (data) => data.label == "Executive Leadership"
          );

          if (executives) {
            const executivePeople = executives.people;

            setExecutivesData(executivePeople);
          }
          const advisors = res.data.find(
            (data) => data.label == "Advisors"
          );

          if (advisors) {
            const advisorPeople = advisors.people;

            setAdvisorsData(advisorPeople);
          }
          const investors = res.data.find(
            (data) => data.label == "Primary Investors"
          );

          if (investors) {
            const investorsPeople = investors.people;

            setInvestorsData(investorsPeople);
          }
          const members = res.data.find(
            (data) => data.label == "Board Members"
          );

          if (members) {
            const membersPeople = members.people;

            setMembersData(membersPeople);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [keyword]);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">People</div>
          <div className="text-grey-2 text-[16px]">
            Add new leaders, members, investors and advisors
          </div>
        </div>
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={onEditClick}>
            Add People
          </button>
        </div>
      </div>

      {/* Body */}

      {/* SEARCH BAR */}

      <Searchbar fetchKeyword={fetchKeyword} />

      {/* DATA */}

      {/* FOUNDERS */}

      {/* {
          foundersData?.length > 0 &&
          <div className="flex flex-col gap-3">
        <div className="text-lg font-semibold leading-7">Founders</div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
          {foundersData?.map((founder, index) => {
            return (
              <Founders
                name={founder?.name}
                designation={founder?.title}
                description={founder?.bio}
                image={founder?.imageUrl}
                id={founder.id}
                handleDelete={deleteFounderHandler}
                linkedin={founder?.linkedinUrl}
                onEdit={editPeopleHandler}
              />
            );
          })}
        </div>
      </div>
        } */}

      {/* EXECUTIVE LEADERSHIP */}
      {executivesData?.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold leading-7">
            Executive Leadership
          </div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
            {executivesData?.map((executive, index) => {
              return (
                <Executives
                  name={executive?.name}
                  designation={executive?.title}
                  image={executive?.imageUrl}
                  id={executive.id}
                  handleDelete={deleteExecutiveHandler}
                  linkedin={executive?.linkedinUrl}
                  onEdit={editPeopleHandler}
                />
              );
            })}
          </div> */}
             <PeopleRanking initialCards={executivesData} onDelete={deleteExecutiveHandler} onEdit={editPeopleHandler} peopleTypeId={1}/>     
        </div>
      )}

      {/* PRIMARY INVESTORS */}
      {investorsData?.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold leading-7">
            Primary Investors
          </div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
            {investorsData?.map((investor, index) => {
              return (
                <Investors
                  name={investor?.name}
                  designation={investor?.title}
                  image={investor?.imageUrl}
                  id={investor.id}
                  handleDelete={deleteInvestorHandler}
                  linkedin={investor?.linkedinUrl}
                  onEdit={editPeopleHandler}
                />
              );
            })}
          </div> */}
           <PeopleRanking initialCards={investorsData} onDelete={deleteInvestorHandler} onEdit={editPeopleHandler} peopleTypeId={2}/>     
        </div>
      )}

      {/* BOARD MEMBERS */}
      {membersData?.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold leading-7">Board Members</div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
            {membersData?.map((member, index) => {
              return (
                <BoardMembers
                  name={member?.name}
                  designation={member?.title}
                  image={member?.imageUrl}
                  id={member.id}
                  handleDelete={deleteMemberHandler}
                  linkedin={member?.linkedinUrl}
                  onEdit={editPeopleHandler}
                />
              );
            })}
          </div> */}
           <PeopleRanking initialCards={membersData} onDelete={deleteMemberHandler} onEdit={editPeopleHandler} peopleTypeId={3}/>     
        </div>
      )}

      {/* ADVISORS */}
      {advisorsData?.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold leading-7">Advisors</div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
            {advisorsData?.map((advisor, index) => {
              return (
                <Advisors
                  name={advisor?.name}
                  designation={advisor?.title}
                  image={advisor?.imageUrl}
                  id={advisor.id}
                  handleDelete={deleteAdvisorHandler}
                  linkedin={advisor?.linkedinUrl}
                  onEdit={editPeopleHandler}
                />
              );
            })}
          </div> */}
             <PeopleRanking initialCards={advisorsData} onDelete={deleteAdvisorHandler} onEdit={editPeopleHandler} peopleTypeId={4}/>   
        </div>
      )}

      {foundersData.length < 1 &&
        executivesData?.length < 1 &&
        membersData?.length < 1 &&
        investorsData?.length < 1 &&
        advisorsData?.length < 1 && <DataNotFound />}
    </div>
  );
}

export default People;
