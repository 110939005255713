import React, { useEffect } from 'react'

import { getOnBoardCookie } from '../auth/cookies'
import { useNavigate } from 'react-router-dom';
import firebase from "../auth/firebaseConfig";
import useFirebaseAuth from '../auth/useFirebaseAuth';
import {removeOnBoardCookie} from "../auth/cookies"

function ProtectedRoutes({component}) {

    // const token = getToken('auth');
    const token = getOnBoardCookie()
    const navigate = useNavigate();
    const {signOut} = useFirebaseAuth();

    useEffect(() => {


    
      function parseJwt() {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
      }


      console.log("EMAIL VERIFIED OR NOT  : ", parseJwt().email_verified);

        if(!token) {
          signOut();
            navigate("/");
        }else if(token &&  parseJwt().email_verified === false){
          signOut();
          removeOnBoardCookie();
          navigate("/");
        }
        
      }, [token, navigate])

  return (
    <div>
      {component}
    </div>
  )
}

export default ProtectedRoutes