// import React, { useState, useEffect } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { postMethod } from "../../../utils/apiMethod";
// import { toastError, toastSuccess } from "../../../utils/Toasters";
// import Award from "../cards/Award";
// import Products from "../cards/Products";

// function AwardRanking({ initialCards, onDelete, onEdit }) {
//   const [cards, setCards] = useState(initialCards);

//   useEffect(() => {
//     setCards(initialCards);
//   }, [initialCards]);

//   // Helper function to reorder items in the list
//   const reorder = (list, startIndex, endIndex) => {
//     const [removed] = list.splice(startIndex, 1);
//     list.splice(endIndex, 0, removed);
//     return list;
//   };

//   // Helper function to split the list into rows of fixed size
//   const splitIntoRows = (list, rowSize) => {
//     const rows = [];
//     for (let i = 0; i < list.length; i += rowSize) {
//       rows.push(list.slice(i, i + rowSize));
//     }
//     return rows;
//   };

//   const rankCard = async (awardId, rank) => {
//     try {
//       var raw = JSON.stringify({
//         awardId: awardId,
//         rank: rank + 1,
//       });
//       let response = await postMethod(`awards/rankAward`, raw);
//       if (response?.status === true) {
//         toastSuccess(response?.message);
//       } else {
//         toastError(response?.message);
//       }
//     } catch (err) {
//       toastError(err?.message);
//     }
//   };

//   const handleOnDragEnd = async (result) => {
//     const { source, destination } = result;

//     // Dropped outside the list
//     if (!destination) return;

//     // Reorder the flat list of cards
//     const reorderedCards = reorder(
//       Array.from(cards),
//       source.index,
//       destination.index
//     );

//     // Set the updated card positions
//     setCards(reorderedCards);

//     // Optionally, update the backend with the new position
//     await rankCard(reorderedCards[destination.index]?.id, destination.index);
//   };

//   // Split the flat list into rows of 4
//   const rows = splitIntoRows(cards, 4);

//   return (
//     <div style={{ overflow: "hidden", padding: "12px" }} className="flex flex-col gap-2">
//       <DragDropContext onDragEnd={handleOnDragEnd}>
//         {rows.map((row, rowIndex) => (
//           <Droppable
//             droppableId={`${rowIndex}`}
//             direction="horizontal"
//             key={rowIndex}
//           >
//             {(provided) => (
//               <div
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//                 style={{
//                   //   display: "grid",
//                   //   gridTemplateColumns: "repeat(4, 1fr)", // Ensure there are 4 cards per row
//                   gridAutoFlow: "row dense", // Ensure dense grid packing
//                   //   gap: "8px",
//                   //   width: "100%",
//                   minHeight: "100px", // Ensure there's a minimum height
//                   overflow: "hidden", // Prevent any overflow during dragging
//                 }}
//                 className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-5"
//               >
//                 {row.map((award, index) => (
//                   <Draggable
//                     key={award?.id?.toString()}
//                     draggableId={award?.id?.toString()}
//                     index={rowIndex * 4 + index} // Correct index for the flat list
//                   >
//                     {(provided, snapshot) => (
//                       <div
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         style={{
//                           ...provided.draggableProps.style,
//                           userSelect: "none",
//                             // background: "red",
//                             borderRadius: "4px",
//                             boxShadow: snapshot.isDragging
//                               ? "0 2px 4px rgba(0,0,0,0.2)"
//                               : "none",
//                           minHeight: "200px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           transition: "transform 0.2s ease", // Smooth transition when cards are reordered
//                         }}
//                         // className="box-shadow1"
//                       >
//                         <Award
//                           logo={award?.awardImageUrl}
//                           title={award?.awardName}
//                           description={award?.achievement}
//                           id={award?.id}
//                           handleDelete={onDelete}
//                           onEdit={onEdit}
//                         />
//                       </div>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         ))}
//       </DragDropContext>
//     </div>
//   );
// }

// export default AwardRanking;


// NEW CODE (og): 
// import React, { useState, useEffect } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { postMethod } from "../../../utils/apiMethod";
// import { toastError, toastSuccess } from "../../../utils/Toasters";
// import Award from "../cards/Award";

// function AwardRanking({ initialCards, onDelete, onEdit }) {
//   const [cards, setCards] = useState(initialCards);

//   useEffect(() => {
//     setCards(initialCards);
//   }, [initialCards]);

//   // Helper function to reorder items in the list
//   const reorder = (list, startIndex, endIndex) => {
//     const [removed] = list.splice(startIndex, 1);
//     list.splice(endIndex, 0, removed);
//     return list;
//   };

//   // Helper function to split the list into rows based on rowSize
//   const splitIntoRows = (list, rowSize) => {
//     const rows = [];
//     for (let i = 0; i < list.length; i += rowSize) {
//       rows.push(list.slice(i, i + rowSize));
//     }
//     return rows;
//   };

//   const rankCard = async (awardId, rank) => {
//     try {
//       const raw = JSON.stringify({
//         awardId: awardId,
//         rank: rank + 1,
//       });
//       let response = await postMethod(`awards/rankAward`, raw);
//       if (response?.status === true) {
//         toastSuccess(response?.message);
//       } else {
//         toastError(response?.message);
//       }
//     } catch (err) {
//       toastError(err?.message);
//     }
//   };

//   const handleOnDragEnd = async (result) => {
//     const { source, destination } = result;

//     // Dropped outside the list
//     if (!destination) return;

//     // Reorder the flat list of cards
//     const reorderedCards = reorder(
//       Array.from(cards),
//       source.index,
//       destination.index
//     );

//     // Set the updated card positions
//     setCards(reorderedCards);

//     // Optionally, update the backend with the new position
//     await rankCard(reorderedCards[destination.index]?.id, destination.index);
//   };

//   // Responsive: Determine how many cards to display in each row based on screen size
//   const getRowSize = () => {
//     const width = window.innerWidth;
//     if (width < 640) return 1; // 1 card for mobile (sm)
//     if (width >= 640 && width < 1024) return 2; // 2 cards for tablets (md)
//     return 4; // 4 cards for desktops (lg+)
//   };

//   const [rowSize, setRowSize] = useState(getRowSize());

//   // Update row size on window resize
//   useEffect(() => {
//     const handleResize = () => setRowSize(getRowSize());
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Split cards into rows based on the determined row size
//   const rows = splitIntoRows(cards, rowSize);

//   return (
//     <div style={{ overflow: "hidden", padding: "12px" }} className="flex flex-col gap-2">
//       <DragDropContext onDragEnd={handleOnDragEnd}>
//         {rows.map((row, rowIndex) => (
//           <Droppable
//             droppableId={`${rowIndex}`}
//             direction="horizontal" // Ensure horizontal drag within rows
//             key={rowIndex}
//           >
//             {(provided) => (
//               <div
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//                 style={{
//                   display: "flex",
//                   gap: "8px",
//                   minHeight: "100px",
//                   overflow: "hidden",
//                   touchAction: "none", // Ensure drag gestures work on mobile
//                 }}
//                 className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-5`} // Responsively control columns
//               >
//                 {row.map((award, index) => (
//                   <Draggable
//                     key={award?.id?.toString()}
//                     draggableId={award?.id?.toString()}
//                     index={rowIndex * rowSize + index} // Adjust index for the flat list
//                   >
//                     {(provided, snapshot) => (
//                       <div
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         style={{
//                           ...provided.draggableProps.style,
//                           userSelect: "none",
//                           zIndex: snapshot.isDragging ? 1000 : "auto",
//                           borderRadius: "4px",
//                           boxShadow: snapshot.isDragging
//                             ? "0 2px 4px rgba(0,0,0,0.2)"
//                             : "none",
//                           opacity: snapshot.isDragging ? 0.9 : 1, // Ensure it's still visible while dragging
//                           minHeight: "200px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           touchAction: "none",
//                           transition: "transform 0.2s ease",
//                           width: "100%", // Ensure the card fills the width on mobile
//                         }}
//                       >
//                         <Award
//                           logo={award?.awardImageUrl}
//                           title={award?.awardName}
//                           description={award?.achievement}
//                           id={award?.id}
//                           handleDelete={onDelete}
//                           onEdit={onEdit}
//                         />
//                       </div>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         ))}
//       </DragDropContext>
//     </div>
//   );
// }

// export default AwardRanking;

// NEW CODE - experiment
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import Award from "../cards/Award";

function AwardRanking({ initialCards, onDelete, onEdit }) {
  const [cards, setCards] = useState(initialCards);

  useEffect(() => {
    setCards(initialCards);
  }, [initialCards]);

  // Helper function to reorder items in the list
  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  // Helper function to split the list into rows based on rowSize
  const splitIntoRows = (list, rowSize) => {
    const rows = [];
    for (let i = 0; i < list.length; i += rowSize) {
      rows.push(list.slice(i, i + rowSize));
    }
    return rows;
  };

  const rankCard = async (awardId, rank) => {
    try {
      const raw = JSON.stringify({
        awardId: awardId,
        rank: rank + 1,
      });
      let response = await postMethod(`awards/rankAward`, raw);
      if (response?.status === true) {
        toastSuccess(response?.message);
      } else {
        toastError(response?.message);
      }
    } catch (err) {
      toastError(err?.message);
    }
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) return;

    // Reorder the flat list of cards
    const reorderedCards = reorder(
      Array.from(cards),
      source.index,
      destination.index
    );

    // Set the updated card positions
    setCards(reorderedCards);

    // Optionally, update the backend with the new position
    await rankCard(reorderedCards[destination.index]?.id, destination.index);
  };

  // Responsive: Determine how many cards to display in each row based on screen size
  const getRowSize = () => {
    const width = window.innerWidth;
    if (width < 640) return 1; // 1 card for mobile (sm)
    if (width >= 640 && width < 1024) return 2; // 2 cards for tablets (md)
    return 4; // 4 cards for desktops (lg+)
  };

  const [rowSize, setRowSize] = useState(getRowSize());

  // Update row size on window resize
  useEffect(() => {
    const handleResize = () => setRowSize(getRowSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Split cards into rows based on the determined row size
  const rows = splitIntoRows(cards, rowSize);

  return (
    <div style={{ overflow: "hidden", padding: "12px" }} className="flex flex-col gap-2">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {rows.map((row, rowIndex) => (
          <Droppable
            droppableId={`${rowIndex}`}
            direction="horizontal" // Ensure horizontal drag within rows
            key={rowIndex}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  gap: "8px",
                  minHeight: "100px",
                  overflow: "hidden",
                  touchAction: "none", // Ensure drag gestures work on mobile
                }}
                className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-5`} // Responsively control columns
              >
                {row.map((award, index) => (
                  <Draggable
                    key={award?.id?.toString()}
                    draggableId={award?.id?.toString()}
                    index={rowIndex * rowSize + index} // Adjust index for the flat list
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          // Only apply necessary styles to the card during dragging
                          zIndex: snapshot.isDragging ? 1000 : "auto",
                          borderRadius: "4px",
                          boxShadow: snapshot.isDragging
                            ? "0 2px 4px rgba(0,0,0,0.2)"
                            : "none",
                          opacity: snapshot.isDragging ? 0.9 : 1,
                          minHeight: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // width: "100%", // Ensure the card fills the width on mobile
                          transition: "transform 0.2s ease",
                        }}
                        className="w-full sm:w-1/2 lg:w-1/4"
                      >
                        <Award
                          logo={award?.awardImageUrl}
                          title={award?.awardName}
                          description={award?.achievement}
                          id={award?.id}
                          handleDelete={onDelete}
                          onEdit={onEdit}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
}

export default AwardRanking;



