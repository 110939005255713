import React from 'react'

function Tooltip({text, children}) {
  return (
    <div className="relative flex flex-col items-center group ">
    {children}
    <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex z-50">
      <span className="relative z-50 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black-1 shadow-lg rounded-md">{text?.length > 0 ? text : "no value added"}</span>
      <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
    </div>
  </div>
  )
}

export default Tooltip