import React, { useRef, useState } from 'react'
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { postFormMethod } from '../../../utils/apiMethod';
import { toastError, toastSuccess } from '../../../utils/Toasters';

function AddTestimonial({productId, onClickClose, setRefresher}) {
    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }

     const [testimonials, setTestimonials] = useState(
        {
          customerName: "",
          companyName: "",
          title: "",
          description: "",
          image:""
        },
      );
      const [testiImage, setTestiImage] = useState("");
      const fileTestiInputRef = useRef(null);

      const handleTestiClick = () => {
        fileTestiInputRef.current.click(); // Trigger the file input dialog
      };

      const handleTestiFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setTestiImage(selectedFile);
       
      };

      const handleTestimonialChange = (e) => {
       
        setTestimonials({...testimonials, [e.currentTarget.name]: e.target.value})
    
    
      };

     const submitHandler = (e) => {
        e.preventDefault();
            var formdata = new FormData();
           
                formdata.append("productId", productId);
                formdata.append("customerName", testimonials?.customerName);
                formdata.append("companyName", testimonials?.companyName);
                formdata.append("description", testimonials?.description);
                formdata.append("title", testimonials?.title);
                if(testiImage){
        
                  formdata.append("image", testiImage)
                }
         
            postFormMethod(`product/addTestimonial`, formdata)
            .then((res) => {
              if (res.status == true) {
        
                console.log("TESTIMONIALS RES : ",res);   
                toastSuccess(res?.message)
                setRefresher(prev => !prev)
                onClickClose();
                
               
              }else{
                toastError(res.message)
              }
            })
            .catch((error) => {
              // setLoading(false);
              toastError(error.message);
              console.log("error.message: ", error.message);
            });
        
          
      };

  return (
    <div className='modal-wrapper'>
    
    <div className='flex flex-col bg-white rounded-2xl w-11/12 lg:w-1/3 p-10  gap-5' style={modalStyle} >
      <div className='flex flex-row-reverse w-full'>
        <div role="button" className='text-sea-green
         border border-sea-green px-5 py-3 rounded-md'
         onClick={onClickClose}
        >
          Close
        </div>
      </div>
  

        <div className={`flex flex-col gap-4 w-full`}>
                <div className="font-light">
                  Testimonial
                  {/* <span className="text-orange-1">*</span> */}
                </div>
                <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-3">
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      placeholder="Client Name"
                      className="focus:border-transparent outline-none bg-[#f2f8f8] m-auto"
                      value={testimonials.customerName}
                      name="customerName"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      placeholder="Client Company Name"
                      className="focus:border-transparent outline-none bg-[#f2f8f8] m-auto"
                      value={testimonials.companyName}
                      name="companyName"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>

                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 w-1/6">
                    <input
                      placeholder="Client Position or Title"
                      className="focus:border-transparent 
                                  outline-none bg-[#f2f8f8] m-auto"
                      value={testimonials.title}
                      name="title"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>
                  
                  <input
                    className="focus:border-transparent outline-none w-full h-full"
                    placeholder="Client Quote (max 270 characters)"
                    value={testimonials.description}
                    name="description"
                    onChange={(e) => handleTestimonialChange(e)}
                    maxLength={270}
                  />


                  {/* Testimonial Image */}

                  <div className="flex gap-2 items-center">

                    <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-14 w-14 
                     cursor-pointer">

                      <div className="flex items-center m-auto h-full w-full rounded-2xl">

                        {/* {
                          !testiImage &&
                          <img
                          src="assets/documentupload.svg"
                          alt="upload"
                          className="m-auto cursor-pointer"
                          
                        />} */}

                    {testiImage ? (
                    <img
                      src={URL.createObjectURL(testiImage)} // Create URL for the selected file
                      alt="Uploaded File"
                      className="flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                      onClick={handleTestiClick}
                    />
                  ) :
                   (
                    <img
                      src="assets/documentupload.svg"
                      alt="upload"
                      className="m-auto cursor-pointer"
                      onClick={handleTestiClick}
                    />
                  )
                   } 

                        <input
                          type="file"
                          ref={fileTestiInputRef}
                          style={{ display: "none" }} // Hide the file input
                          onChange={handleTestiFileChange}
                          accept="image/*" // Accept only image files
                        />
                      </div>
                    </div>
                    <div className="text-grey-7">( Client Photo or Company Logo )</div>
                  </div>



                </div>
            
              </div>

        <div className='flex justify-end w-full'>

            <button className='bg-sea-green text-white text-xl py-3 w-full lg:w-3/12 rounded-md' onClick={submitHandler}>Add</button>

        </div>
      
    </div>
  </div>
  )
}

export default AddTestimonial
