import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import Award from "../../general/cards/Award";
import { deleteMethod, getMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataNotFound from "../../general/DataNotFound";
import AwardRanking from "../../general/ranking/AwardRanking";

function AwardsAdd({onEditClick, fetchIsAwardEdit}) {

    const {setLoading} = useContext(StateContext);
    const [awards, setAwards] = useState([])

    const deleteAwardHandler = (awardId) => {
        // setDelete(false);
        deleteMethod(`awards/deleteAward/${awardId}`)
          .then((res) => {
            console.log("NEW : ", res)
            // toastWarn(res.message)
            if (res.status == true) {
              //   setOpen(false);
              setAwards(
                awards.filter((award) => award.id != awardId)
              );
                toastSuccess(res.message);
            }
            else{
              toastError(res.message)
            }
           
          })
          .catch((error) => {
            toastError(error.message);
            console.log("Error: ", error.message);
          });
      };
      function editAwardHandler(bool, id) {
        const obj = {
          isEdit: bool,
          awardId: id,
        };
        fetchIsAwardEdit(obj);
      }

    useEffect(() => {
        setLoading(true);
    
        getMethod(`awards/getMyAward`)
          .then((res) => {
            setLoading(false)
            if (res?.status) {
              setAwards([...res.data?.results]);
            console.log("RES: ", res)
             
            }
           
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);

      useEffect(() => {

        window.scrollTo(0,0)
         
      }, [])

    return (
      <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
           {/* header */}
           <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
  
                  <div className="flex flex-col">
                      <div className="font-medium text-[28px]">
                          Awards
                      </div>
                  
                  </div>
                  <div className="flex lg:justify-end mt-5 lg:mt-0">
                      <button className="btn-sea-green-thick" onClick={onEditClick}>
                      Add Award
                      </button>
                  </div>
  
          </div>

          {
            awards?.length > 0 ? 
              // <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">

              //   {
                
              //       awards.map((award, index)=>{
              //           return(
              //               <div key={index}>

              //                   <Award logo={award.awardImageUrl} title={award.awardName} 
              //                   description={award.achievement} id={award.id} 
              //                   handleDelete={deleteAwardHandler} onEdit={editAwardHandler}/>
              //               </div>
              //           )
              //       })

                                  
              //   }    
              // </div>

                <AwardRanking initialCards={awards} onDelete={deleteAwardHandler} onEdit={editAwardHandler} />       
              :
              <DataNotFound/>
          }


          {/* <ToastContainer/> */}
      </div>
    );
  }
  
  export default AwardsAdd;
  