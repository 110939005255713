import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../../Context/StateContext';
import { getMethod } from '../../../utils/apiMethod';
import { toastError } from '../../../utils/Toasters';
import DataNotFound from '../../general/DataNotFound';
import FeedbackModal from './FeedbackModal';

function Feedback() {
    const { setLoading } = useContext(StateContext);
    const [feedbackData, setFeedbackData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [feedbackId, setFeedbackId] = useState(null)
    const onClickHandler = (id) => {
        setFeedbackId(id)
        setOpenModal(true);
    }
    useEffect(() => {
        setLoading(true);

    window.scrollTo(0,0)
 
    
        getMethod(`company/getUserFeedbacks`)
          .then((res) => {
            setLoading(false);
            if (res?.status) {
              console.log("Response content : ", res.feedbacks?.results);
    
              setFeedbackData([...res.feedbacks?.results]);
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);
  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
    {/* header */}
    <div className="flex justify-between items-center">

           <div className="flex flex-col">
               <div className="font-medium text-[28px]">
                   Feedback
               </div>
               <div className="text-grey-2 text-[16px]">
               User feedback history data
               </div>
           
           
           </div>
       

   </div>

   {/* Body */}

   {
       feedbackData?.length > 0 ?
   <div className="flex flex-col gap-3 w-full overflow-x-scroll hide-scrollbar">

        {/* Headings */}

       <div className='grid grid-cols-address-cols gap-4 text-base font-normal justify-between min-w-fit'>

        <div>
            Feedback
        </div>
        <div>
            User
        </div>
        <div>
            Phone
        </div>
        <div>
            Email
        </div>
           
       </div>

       {/* Feedback rows */}

       {
        feedbackData.map((feedback, index) => {
            return(


       <div key={index} className='grid grid-cols-address-cols gap-4 border min-w-fit
        border-grey-14 rounded-2xl p-3 text-grey-9 cursor-pointer hover:bg-grey-5 justify-between'
        onClick={()=>onClickHandler(feedback.id)}>

       <div className='flex gap-2'>

           <div className='w-1/2'>

               <img src={feedback.userFeedback.profilePicUrl ? feedback.userFeedback.profilePicUrl : '/assets/image-not-found.png'} className='rounded-lg w-20 h-20'/>

           </div>

           <div className='flex flex-col gap-1 w-1/2'> 

           <div className='font-semibold text-xl text-black-1 max-w-prose line-clamp-1 capitalize'>
              {feedback.title}
           </div>
           <div className='max-w-prose line-clamp-2'>
             {feedback.description}
           </div>

           </div>

       </div>

       <div className='capitalize flex items-center'>
           {feedback.userFeedback.name}
       </div>

       <div className="flex items-center">
       {feedback.userFeedback.phone? feedback.userFeedback.phone : "-"}
       </div>

       <div className="flex items-center">
      {feedback.userFeedback.email}
       </div>

      </div>

            )
        })
       }

    

   
   </div> :
   <DataNotFound/>

   }

{
        openModal && <FeedbackModal closeHandler={()=>setOpenModal(false)} id={feedbackId}/>
       }

</div>
  )
}

export default Feedback
