import React, { useState } from 'react'

function Searchbar({fetchKeyword}) {
    const [keyword , setKeyword] = useState('')
  return (
    
    <div className="flex items-center rounded-lg border border-grey-7 h-12">
    <div className=" border-y-0 border-l-0 h-full flex justify-center items-center pl-4">
      <img src="assets/search.svg" alt="search" className="" />
    </div>
    <input
      className="focus:border-transparent outline-none mx-5 w-full h-full"
      placeholder="Search"
      value={keyword}
      onChange={ e => { 
        setKeyword(e.target.value); 
        if(e.target.value.length >=3 ){

            fetchKeyword(`=${e.target.value}`)
        }else{
            fetchKeyword(``)
        }
        
      } }
    />
  </div>
  )
}

export default Searchbar
