import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../../Context/StateContext';
import { toastError, toastSuccess } from '../../../utils/Toasters';
import { postFormMethod, postMethod } from '../../../utils/apiMethod';
import { ToastContainer } from 'react-toastify';

function NewsAdd({onBackClick, fetchIsNewsEdit}) {
  const {setNewsLoading} = useContext(StateContext);

  const [articleLink, setArticleLink] = useState("")

  const submitHandler = (e) => {
    setNewsLoading(true);

    console.log("ARTICLE LIINK: ", articleLink)
    // const isCorrect = new URL(articleLink)
    // console.log("is correct : ", isCorrect)

    try {
      const isCorrect = new URL(articleLink);
      console.log("Valid URL:", isCorrect);

      // checking of the values changed in field

    let raw;
  
    if(articleLink.length > 0){

    raw = JSON.stringify({

      url : articleLink
      
      });
    }
      postMethod(`news/addNews`, raw)
        .then((res) => {
          setNewsLoading(false)
          if (res.status == true) {
      
            console.log("RESPONSE : ", res);
  
           
              toastSuccess(res.message);
              // onEditClick();
              onBackClick()
              fetchIsNewsEdit({isEdit:true , newsId: res?.data?.id}, 1)
         
          }
          else{
            toastError(res.message)
          }
        })
        .catch((error) => {
          setNewsLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
   
    } catch (error) {
      setNewsLoading(false);
      toastError(error.message)
      console.log("Invalid URL:", error.message);
    }
   

    
      
    
   

  };

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div">
    {/*  Header */}
    <div className="flex flex-col gap-3">
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex gap-3">
          <div onClick={onBackClick} className="cursor-pointer">
            <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
          </div>

          <div className="flex flex-col">
            <div className="font-medium text-[28px]">
              Add News 
            </div>
            <div className="text-grey-2">Add new article</div>
          </div>
        </div>

        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={submitHandler}>Add</button>
        </div>
      </div>
    </div>

    {/* Form */}

    <div className="flex flex-col gap-5">
      {/* COMPANY DETAILS */}


          {/* Testimonials */}
          <div className="form-outline-wrapper-div p-8 gap-5">
                <div>
                    Article Link<span className="text-orange-1">*</span>
                </div>

               
                    <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
                        <input
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        placeholder="Link"
                        type='text'
                        onChange={e=> setArticleLink(e.target.value)}
                        />
                        
                    </div>
                
             
          </div>

    </div>

    {/* Save changes button */}

    <div className="flex flex-row-reverse">
                  <div className="flex lg:justify-end w-full lg:w-2/12">
                  <button className="btn-sea-green text-xl w-full" onClick={submitHandler}>Add</button>
                  </div>
        </div>

        {/* <ToastContainer/> */}
  </div>
  )
}

export default NewsAdd
