import React, { useEffect, useState } from "react";
import { getMethod, postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import LocalLoader from "../../general/LocalLoader";
import SpinLoader from "../../general/SpinLoader";

function MarketCategories({ existingCategories, setStepper, redirectToMain }) {
  console.log("existingCategories : ", existingCategories);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] =
    useState(existingCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [customCategory, setCustomCategory] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const getMarketCategoriesApi = () => {
    setIsLoading(true);
    getMethod(`company/marketCategories`)
      .then((res) => {
        setIsLoading(false);
        if (res?.status) {
          setCategories(res.marketCategories);
          // if response has "other" field , set selectedOther = true , setCustomCategory = other's value
        } else {
          toastError(res?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  const addOtherCategory = async(e) => {
    e.preventDefault();
    var raw = JSON.stringify({
      name: customCategory,
    });
    postMethod(`company/addMarketCategory`, raw)
      .then((res) => {
        if (res.status == true) {
          console.log("RESPONSE : ", res.data);
          toastSuccess(res?.message);
          setSelectedCategories([...selectedCategories, res?.data?.id]);
          setCategories([...categories, res?.data])
          setIsOtherSelected(false)
        } else {
          toastError(res?.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }

  const submitHandler = async(e) => {
    e.preventDefault();
    if (selectedCategories?.length > 0 || isOtherSelected) {
      var raw = JSON.stringify({
        marketCategoryIds: selectedCategories,
      });

      postMethod(`company/updateCompanyMarketCategories`, raw)
        .then((res) => {
          if (res.status == true) {
            console.log("RESPONSE : ", res.data);

            redirectToMain();
            toastSuccess(res?.message);
          } else {
            toastError(res?.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    } else {
      toastError("Atleast 1 market category required");
    }
  };

  useEffect(() => {
    getMarketCategoriesApi();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log("EXISTING CATEGORIES : ", existingCategories);
    setSelectedCategories(existingCategories);
  }, [existingCategories]);

  const handleCheckboxChange = (event, categoryId) => {
    const isChecked = event.target.checked;

    if (isChecked && selectedCategories.length >= 6) {
      toastError("You can select a maximum of 6 categories.");
      return;
    }

    if (isChecked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    }
  };

  const handleRadioChange = (event) => {
    setIsOtherSelected(event.target.checked);
  };

  return (
    <>
      <form
        className="container mx-auto flex flex-col gap-8"
        onSubmit={submitHandler}
      >
        {/*  Header */}
        <div className="flex flex-col gap-3">
          <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
            <div className="xs:flex-col lg:flex gap-3">
              <div
                className="cursor-pointer"
                role="button"
                onClick={() => setStepper()}
              >
                <img src="/assets/arrow-back-circle.svg" alt="arrow-back" />
              </div>

              <div className="flex flex-col">
                <div className="font-medium text-[28px]">Market Categories</div>

                <div className="text-grey-2 mt-3 lg:mt-0">
                  Add Upto 6 Market Categories (
                  {`${selectedCategories?.length} selected`})
                </div>
              </div>
            </div>

            <div className="mt-5 lg:mt-0 w-1/2 lg:w-2/12">
              <button
                className="btn btn-sea-green font-semibold w-full"
                type="submit"
              >
                {isLoading ? <SpinLoader /> : "Submit"}
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <LocalLoader />
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {categories?.map((item, index) => (
                <div key={index} className="bg-white p-4 rounded shadow">
                  <div className="flex items-center">
                    <input
                      id={item?.id}
                      type="checkbox"
                      name={item?.name}
                      className="mx-2 accent-sea-green accent-text-white h-4 w-4 cursor-pointer"
                      checked={selectedCategories.includes(item.id)}
                      onChange={(e) => handleCheckboxChange(e, item.id)}
                    />
                    <label htmlFor={item?.id} className="cursor-pointer">
                      {item?.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center mt-10 mb-5">
              <input
                id="other"
                type="checkbox"
                name="category"
                className="mx-2 accent-sea-green accent-text-white h-4 w-4 cursor-pointer"
                checked={isOtherSelected}
                onChange={handleRadioChange}
              />
              <label htmlFor="other" className="cursor-pointer">
                Other
              </label>
            </div>
          </>
        )}
      </form>

      {isOtherSelected && (
        // <div className="flex items-center mt-4">
        //   <label htmlFor="custom-category" className="mr-2">
        //     Enter custom category:
        //   </label>
        //   <input
        //     type="text"
        //     id="custom-category"
        //     value={customCategory}
        //     onChange={(e) => setCustomCategory(e.target.value)}
        //     className="p-2 border rounded"
        //     placeholder="Enter custom category"
        //   />
        // </div>

        <form onSubmit={addOtherCategory} className="flex gap-2 items-start">
          <div className="w-1/2 flex flex-col">
            <div
              className={`flex items-center rounded-lg border border-grey-7 h-12 w-full`}
            >
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Enter custom category"
                required={isOtherSelected}
                maxLength={50}
                type="text"
                id="custom-category"
                value={customCategory}
                onChange={(e) => setCustomCategory(e.target.value)}
              />
            </div>
            <div className="text-grey-8">(max 50 characters)</div>
          </div>
          <button
            type="submit"
            className="w-1/4 btn btn-sea-green font-semibold"
          >
            Add Category
          </button>
        </form>
      )}
    </>
  );
}

export default MarketCategories;
