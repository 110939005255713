import {React, useState, useContext} from 'react'
import { toastError, toastSuccess } from '../../../../utils/Toasters';
import { postFormMethod, postMethod } from '../../../../utils/apiMethod';
import { StateContext } from '../../../../Context/StateContext';
import { ContentVideoContext } from '../../../../Context/ContentVideoContext';

function MarkVideoModal({contentId, clickHandler, setRefresher}) {

  const {setLoadingTopContent} = useContext(StateContext);
  const {setCallTopContentApi} = useContext(ContentVideoContext);

    const markVideoHandler = (videoMark) => {
       
        // setLoadingTopContent(true);

        var raw = {
          "contentId" : contentId,
          "markedVideo" : videoMark
        }
       
       raw = JSON.stringify(raw)

    
        postMethod(`content/updateContent`, raw)
          .then((res) => {
           
            // setLoadingTopContent(false);

            setCallTopContentApi(prev => !prev)
            setRefresher()

            toastSuccess(res?.message);
            clickHandler()
           
          
          })
          .catch((error) => {
            // setLoadingTopContent(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
    }
  return (
    <div className="absolute bottom-0 right-0 bg-white box-shadow2
    rounded-md flex flex-col gap-5 text-sm p-2 w-[120px]">
         <div role='button' className='cursor-pointer' onClick={() => markVideoHandler('featuredVideo')}>
            Featured video
         </div>
        
         <div role='button'className='cursor-pointer' onClick={() => markVideoHandler('videoCall')} >
              First call video
         </div>
         <div role='button' className='cursor-pointer' onClick={() => markVideoHandler('demoVideo')} >
              Demo video
         </div>
    </div>
  )
}

export default MarkVideoModal
