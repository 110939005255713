// import icons
import { useContext, useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { getMethod } from "../../utils/apiMethod";
import { StateContext } from "../../Context/StateContext";
import { getCompanyCookie, removeCompanyCookie, removeOnBoardCookie, removeToken } from "../../auth/cookies";
import { toastError, toastInfo } from "../../utils/Toasters";
import { useNavigate } from "react-router";
import useFirebaseAuth from "../../auth/useFirebaseAuth";

function Header() {
  const { setLoading } = useContext(StateContext);

  const ownerName = getCompanyCookie("ownerName");
  const ownerImageUrl = getCompanyCookie("ownerImageUrl");

  const companyName = getCompanyCookie("companyName");
  const companyLogo = getCompanyCookie("thumbnailUrl");
  const companyBio = getCompanyCookie("description");

  const {
    setCompanyOwnerName,
    setCompanyName,
    setCompanyLogo,
    setCompanyBio,
    refreshHeader,
  } = useContext(StateContext);


  const [name, setName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    // setName(ownerName);
    // setThumbnail(ownerImageUrl);

    // // context variables
    // setCompanyOwnerName(ownerName);
    // setCompanyName(companyName);
    // setCompanyLogo(companyLogo);
    // setCompanyBio(companyBio);

    // setLoading(true);
    getMethod(`company/getMyCompanyDetails`)
      .then((res) => {
        // setLoading(false);

        if (res?.status) {
          console.log(res);

          setName(res?.company?.company?.ownerName);
          setThumbnail(res?.company?.company?.ownerImageUrl);

          // context variables
          setCompanyOwnerName(res?.company?.company?.ownerName);
          setCompanyName(res?.company?.company?.name);
          setCompanyLogo(res?.company?.company?.thumbnailUrl);
          setCompanyBio(res?.company?.company?.description);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);

        console.log("error.message: ", error.message);
      });
  }, [refreshHeader]);

  return (
    <>
      {/* Desktop View */}

      <div className="bg-white fixed top-0 left-0 w-full z-50 hidden lg:block">
        <div className="flex">
          <div className="w-1/4 bg-dark-blue-1 h-3"> </div>
          <div className="w-1/4 bg-aqua-1 h-3"> </div>
          <div className="w-1/4 bg-sea-green h-3"> </div>
          <div className="w-1/4 bg-yellow-1 h-3"> </div>
        </div>
        <div className="flex justify-center mt-3 mb-5">
          <div className="flex justify-between items-center w-10/12">
            <div className="">
              <img src="assets/FutrLogo.png" alt="" className="h-24" />
            </div>

            <div className="w-1/6">
              <button className="btn-sea-green w-full h-full">
                <div className="flex justify-evenly items-center w-full h-full">
                  <div className="flex items-center">
                    <img
                      src={
                        thumbnail ? thumbnail : `/assets/image-not-found.png`
                      }
                      alt=""
                      className="rounded-full w-14 h-14 object-cover"
                    />
                  </div>
                  <div className="text-xl capitalize truncate">
                    {name?.split(" ")[0]}
                  </div>

                  <div>{/* <RiArrowDownSLine size={30}/> */}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
