import React from 'react'
import { useNavigate } from 'react-router'

function PageNotFound() {
  const navigate = useNavigate()
  const goToHomeHandler = () => {
    navigate("/")
    
  }
  return (
    <div className="w-screen h-screen m-auto w-full h-full flex justify-center items-center justify-center flex-col">
    <img src="/assets/404.svg" alt="page not found" className="w-10/12 lg:w-1/3"/>
    
    <button className="bg-sea-green rounded-full px-20 py-5 text-white mt-10" onClick={goToHomeHandler}>Back to Home</button>
  </div>
  )
}

export default PageNotFound